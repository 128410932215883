import React, { useEffect } from "react";
import "./LatestNewsComp.css";

// import latestNewsImage1 from "../../assets/homeImages/latestNews/1.png";
// import latestNewsImage2 from "../../assets/homeImages/latestNews/2.png";
// import latestNewsImage3 from "../../assets/homeImages/latestNews/3.png";

import { IoIosArrowForward } from "react-icons/io";

import { Link } from "react-router-dom";

import { useLocation } from "react-router-dom";
import { useContext } from "react";
import ApiContext from "../../context/Api";

export default function LatestNewsComp({ compstyle, latestNewsData }) {
  const location = useLocation();

  const { setCurrentPath } = useContext(ApiContext);

  useEffect(() => {
    setCurrentPath(location.pathname);
  });

  // const latestNewsData = [
  //   {
  //     image: latestNewsImage1,
  //     title: "Title 1",
  //     text: "Twin Forestland Home",
  //   },
  //   {
  //     image: latestNewsImage2,
  //     title: "Title 2",
  //     text: "Twin Forestland Home",
  //   },
  //   {
  //     image: latestNewsImage3,
  //     title: "Title 3",
  //     text: "Twin Forestland Home",
  //   },
  //   {
  //     image: latestNewsImage1,
  //     title: "Title 4",
  //     text: "Twin Forestland Home",
  //   },
  //   {
  //     image: latestNewsImage2,
  //     title: "Title 5",
  //     text: "Twin Forestland Home",
  //   },
  //   {
  //     image: latestNewsImage3,
  //     title: "Title 6",
  //     text: "Twin Forestland Home",
  //   },
  // ];

  const renderedNewsData = latestNewsData.map((data, index) => {
    return (
      <div
        key={`${data.title}${index}`}
        className='latestNewsComp-section-card flex flex-col min-w-[400px] '>
        <img
          className='w-[400px] h-[400px]'
          src={data.image}
          alt={`${data.title}${index}`}
        />
        <Link to={`/Blogs/${data.title}`}>
          <div className='cursor-pointer text-white flex flex-row justify-between bg-black items-center p-[1rem]'>
            <div className='flex flex-col'>
              <h3 className='text-[#FF965A] text-[25px]'>{data.title}</h3>
              <p>{`${data.text.substr(0, 20)}...`}</p>
            </div>
            <IoIosArrowForward className='text-[40px]' />
          </div>
        </Link>
      </div>
    );
  });
  return (
    <div className='latestNewsComp-section flex flex-col py-[2rem] px-[4rem] gap-[2rem]'>
      <div className={`latestNewsComp-section-cards ${compstyle}`}>
        {renderedNewsData}
      </div>
    </div>
  );
}
