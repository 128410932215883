import React, { useEffect } from "react";
import "./Services.css";

import servicesHeroImage from "../../assets/services/servicesHeroImage.jpg";

import HeroSectionOfPages from "../../components/heroSectionOfPages/HeroSectionOfPages";

// import serviceImage1 from "../../assets/services/servicesImages/1.jpg";

import ServicesComp from "../../components/servicesComp/ServicesComp";

import ContactImage from "../../assets/contactBGImg.jpg";

import ContactComp from "../../components/contactComp/ContactComp";

import { useLocation } from "react-router-dom";
import { useContext } from "react";
import ApiContext from "../../context/Api";

export default function Services({ servicesData }) {
  const location = useLocation();

  const { setCurrentPath } = useContext(ApiContext);

  useEffect(() => {
    setCurrentPath(location.pathname);
  });

  // const servicesData = [
  //   {
  //     image: serviceImage1,
  //     serviceName: "Architect Design",
  //     text: "Hello i am a text for this card Hello i am a text for this card Hello i am a text for this card Hello i am a text for this card Hello i am a text for this card Hello i am a text for this card Hello i am a text for this card Hello i am a text for this card",
  //     link: "",
  //   },
  //   {
  //     image: serviceImage1,
  //     serviceName: "Landscape Planner",
  //     text: "Hello i am a text for this card Hello i am a text for this card Hello i am a text for this card Hello i am a text for this card Hello i am a text for this card Hello i am a text for this card Hello i am a text for this card Hello i am a text for this card",
  //     link: "",
  //   },
  //   {
  //     image: serviceImage1,
  //     serviceName: "3D model of interior",
  //     text: "Hello i am a text for this card Hello i am a text for this card Hello i am a text for this card Hello i am a text for this card Hello i am a text for this card Hello i am a text for this card Hello i am a text for this card Hello i am a text for this card",
  //     link: "",
  //   },
  // ];
  return (
    <div className='services-section'>
      <HeroSectionOfPages
        image={servicesHeroImage}
        heading={"Our Services"}
        link={"/"}
      />

      <div className='services-section-content flex flex-col text-white gap-[1rem] py-[2rem] px-[4rem]'>
        <p>
          The fusion of experience and skill results in enduring intricacy and
          an iconic flair that give identity to your space.
        </p>
        <p>
          Unfold Concetto Studio is a comprehensive interior & architectural
          design company providing solutions for both residential and smaller
          commercial ventures. Our services encompass interior design,
          architectural design, 3D model interior design and decoration,
          covering regions across India.
        </p>
      </div>

      <ServicesComp servicesData={servicesData} />

      <div className='aboutus-section-contact relative'>
        <img className='' src={ContactImage} alt='ContactImage' />
        <div className='absolute top-0 w-full h-full'>
          <ContactComp />
        </div>
      </div>
    </div>
  );
}
