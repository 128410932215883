import "./App.css";

import React, { useEffect } from "react";

import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";

// Pages
import Homepage from "./pages/homepage/Homepage";
import AboutUs from "./pages/aboutUs/AboutUs";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import Services from "./pages/Services/Services";
import Projects from "./pages/projects/Projects";
import Blog from "./pages/blog/Blog";
import ContactUs from "./pages/contactUs/ContactUs";
import SingleBlogPage from "./pages/blog/singleBlogPage/SingleBlogPage";

import { useContext } from "react";
import ApiContext from "./context/Api";
import SingleProjectPage from "./pages/projects/singleProjectPage/SingleProjectPage";
import SingleServicePage from "./pages/Services/singleServicePage/SingleServicePage";

// HERO CAROUSEL DATA
import heroImage1 from "./assets/homeImages/heroImages/1.jpg";
import heroImage2 from "./assets/homeImages/heroImages/2.jpg";
import heroImage3 from "./assets/homeImages/heroImages/3.jpg";
import heroImage4 from "./assets/homeImages/heroImages/4.png";
import heroImage5 from "./assets/homeImages/heroImages/5.jpg";
import heroImage6 from "./assets/homeImages/heroImages/6.png";

// BLOG IMAGES
import latestNewsImage1 from "./assets/homeImages/latestNews/1.png";
import latestNewsImage2 from "./assets/homeImages/latestNews/2.png";
import latestNewsImage3 from "./assets/homeImages/latestNews/3.png";

// PROJECT IMAGES
// import ourProjectImage1 from "./assets/projects/projects/1bhk small house )shaki house 1(1).png";
// import ourProjectImage2 from "./assets/projects/projects/(2bhk design) tanmay Bhai foyar.png";
// import ourProjectImage3 from "./assets/projects/projects/project 1(outdoor cafa on road side) mayur Bhai cafe (2020).png";
// import ourProjectImage4 from "./assets/projects/projects/project 11(resident bedroom design) Akash Bhai (2023).png";
// import ourProjectImage5 from "./assets/projects/projects/project 2(office interior)entrepreneur inside out(2020).png";
// import ourProjectImage6 from "./assets/projects/projects/project 5(institute concept)Indian institute of skill (IISs).png";
// import ourProjectImage7 from "./assets/projects/projects/project 6(semi opean sitting area ) sankat (2022).png";
// import ourProjectImage8 from "./assets/projects/projects/project 7(dressiing) Rahul Bhai dressings (2022).png";
// import projectPageMainImage from "./assets/projects/singleProjectPage/projectPage/project1/main.jpg";
// import projectPageImage1 from "./assets/projects/singleProjectPage/projectPage/project1/1.jpg";
// import projectPageImage2 from "./assets/projects/singleProjectPage/projectPage/project1/2.jpg";

// import project1PageMainImage from "./assets/projects/singleProjectPage/1/Landscape.png";

// Project Images
import project3DVisualizationProject1MainImage from "./assets/projects/projects/3d Visualization/Project 11 (Resident Bedroom)/1.jpg";
import project3DVisualizationProject1Image1 from "./assets/projects/projects/3d Visualization/Project 11 (Resident Bedroom)/2.jpg";
import project3DVisualizationProject1Image2 from "./assets/projects/projects/3d Visualization/Project 11 (Resident Bedroom)/3.jpg";
import project3DVisualizationProject2MainImage from "./assets/projects/projects/3d Visualization/Project 7 Dressing Rahul Bhai/1.jpg";
import project3DVisualizationProject2Image1 from "./assets/projects/projects/3d Visualization/Project 7 Dressing Rahul Bhai/2.jpg";
import project3DVisualizationProject2Image2 from "./assets/projects/projects/3d Visualization/Project 7 Dressing Rahul Bhai/3.jpg";

import projectArchitectureDesignProject1MainImage from "./assets/projects/projects/Architecture Design/project 1(outdoor cafa on road side) mayur Bhai cafe (2020)/3.jpg";
import projectArchitectureDesignProject1Image1 from "./assets/projects/projects/Architecture Design/project 1(outdoor cafa on road side) mayur Bhai cafe (2020)/1.jpg";
import projectArchitectureDesignProject1Image2 from "./assets/projects/projects/Architecture Design/project 1(outdoor cafa on road side) mayur Bhai cafe (2020)/2.jpg";
import projectArchitectureDesignProject2MainImage from "./assets/projects/projects/Architecture Design/project 3(site office) royal bungalows (2021)/1.jpg";
import projectArchitectureDesignProject2Image1 from "./assets/projects/projects/Architecture Design/project 3(site office) royal bungalows (2021)/2.jpg";
import projectArchitectureDesignProject2Image2 from "./assets/projects/projects/Architecture Design/project 3(site office) royal bungalows (2021)/3.jpg";
import projectArchitectureDesignProject3MainImage from "./assets/projects/projects/Architecture Design/project 5(institute concept)Indian institute of skill (IISs)/1.JPG";
import projectArchitectureDesignProject3Image1 from "./assets/projects/projects/Architecture Design/project 5(institute concept)Indian institute of skill (IISs)/2.JPG";
import projectArchitectureDesignProject3Image2 from "./assets/projects/projects/Architecture Design/project 5(institute concept)Indian institute of skill (IISs)/3.JPG";
import projectArchitectureDesignProject4MainImage from "./assets/projects/projects/Architecture Design/project 6(semi opean sitting area ) sankat (2022)/1.jpg";
import projectArchitectureDesignProject4Image1 from "./assets/projects/projects/Architecture Design/project 6(semi opean sitting area ) sankat (2022)/2.jpg";
import projectArchitectureDesignProject4Image2 from "./assets/projects/projects/Architecture Design/project 6(semi opean sitting area ) sankat (2022)/3.jpg";

import projectInteriorDesignProject1MainImage from "./assets/projects/projects/Interior Design/project 10(office)bhagwati spring(2023)/01.jpg";
import projectInteriorDesignProject1Image1 from "./assets/projects/projects/Interior Design/project 10(office)bhagwati spring(2023)/02.jpg";
import projectInteriorDesignProject1Image2 from "./assets/projects/projects/Interior Design/project 10(office)bhagwati spring(2023)/03.jpg";
import projectInteriorDesignProject2MainImage from "./assets/projects/projects/Interior Design/project 12(2bhk design) tanmay Bhai (2023)/1.jpg";
import projectInteriorDesignProject2Image1 from "./assets/projects/projects/Interior Design/project 12(2bhk design) tanmay Bhai (2023)/2.jpg";
import projectInteriorDesignProject2Image2 from "./assets/projects/projects/Interior Design/project 12(2bhk design) tanmay Bhai (2023)/3.jpg";
import projectInteriorDesignProject3MainImage from "./assets/projects/projects/Interior Design/project 13(1 bhk design) chirag patel(2023)/1.jpg";
import projectInteriorDesignProject3Image1 from "./assets/projects/projects/Interior Design/project 13(1 bhk design) chirag patel(2023)/2.jpg";
import projectInteriorDesignProject3Image2 from "./assets/projects/projects/Interior Design/project 13(1 bhk design) chirag patel(2023)/3.jpg";
import projectInteriorDesignProject4MainImage from "./assets/projects/projects/Interior Design/project 2(office interior)entrepreneur inside out(2020)/1.jpg";
import projectInteriorDesignProject4Image1 from "./assets/projects/projects/Interior Design/project 2(office interior)entrepreneur inside out(2020)/2.jpg";
import projectInteriorDesignProject4Image2 from "./assets/projects/projects/Interior Design/project 2(office interior)entrepreneur inside out(2020)/3.jpg";
import projectInteriorDesignProject5MainImage from "./assets/projects/projects/Interior Design/project 4(1bhk small house )shaki house (2021)/bedroom.jpg";
import projectInteriorDesignProject5Image1 from "./assets/projects/projects/Interior Design/project 4(1bhk small house )shaki house (2021)/kitchen.jpg";
import projectInteriorDesignProject5Image2 from "./assets/projects/projects/Interior Design/project 4(1bhk small house )shaki house (2021)/living.jpg";
import projectInteriorDesignProject6MainImage from "./assets/projects/projects/Interior Design/project 8(1bhk apartment) pream Bhai (2022)/1 Bedroom.jpg";
import projectInteriorDesignProject6Image1 from "./assets/projects/projects/Interior Design/project 8(1bhk apartment) pream Bhai (2022)/2 Living room.jpg";
import projectInteriorDesignProject6Image2 from "./assets/projects/projects/Interior Design/project 8(1bhk apartment) pream Bhai (2022)/3 Living room.jpg";
import projectInteriorDesignProject7MainImage from "./assets/projects/projects/Interior Design/project 9(2 bhk with classic design theam)Shivam house (2022)/1 Bedroom.jpg";
import projectInteriorDesignProject7Image1 from "./assets/projects/projects/Interior Design/project 9(2 bhk with classic design theam)Shivam house (2022)/2 Bedroom.jpg";
import projectInteriorDesignProject7Image2 from "./assets/projects/projects/Interior Design/project 9(2 bhk with classic design theam)Shivam house (2022)/3 Living Dining Kitchen.jpg";

import projectSitePicturesMainImage from "./assets/projects/projects/Site Pics/1.jpg";
import projectSitePicturesImage1 from "./assets/projects/projects/Site Pics/1.jpg";
import projectSitePicturesImage2 from "./assets/projects/projects/Site Pics/1.jpg";

// SERVICES IMAGES
// import serviceImage1 from "./assets/services/servicesImages/1.jpg";
// import servicePageMainImage from "./assets/services/singleService/service1/main.jpg";
// import servicePageImage1 from "./assets/services/singleService/service1/1.jpg";
// import servicePageImage2 from "./assets/services/singleService/service1/2.jpg";

export default function App() {
  const { currentPath } = useContext(ApiContext);

  useEffect(() => {
    window.scroll(0, 0);

    return () => {
      return null;
    };
  }, [currentPath]);

  // console.log(currentPath);
  // ////// Data

  // Hero Carousel Data
  const heroCarouselData = [
    {
      img: heroImage1,
      text: "Unfold Concetto Studio",
      subtext:
        "The fusion of experience and expertise results in everlasting intricacy and an iconic flair that characterize your environment.",
      subtext2: "",
    },
    {
      img: heroImage2,
      text: "Unfold Concetto Studio",
      subtext:
        "The fusion of experience and expertise results in everlasting intricacy and an iconic flair that characterize your environment.",
      subtext2: "",
    },
    {
      img: heroImage3,
      text: "Unfold Concetto Studio",
      subtext:
        "The fusion of experience and expertise results in everlasting intricacy and an iconic flair that characterize your environment.",
      subtext2: "",
    },
    {
      img: heroImage4,
      text: "Unfold Concetto Studio",
      subtext:
        "Effective design seamlessly integrates interior components and architectural intricacies that mirror your individuality and elevate your way of living.",
      subtext2: "",
    },
    {
      img: heroImage5,
      text: "Unfold Concetto Studio",
      subtext:
        "Effective design seamlessly integrates interior components and architectural intricacies that mirror your individuality and elevate your way of living.",
      subtext2: "",
    },
    {
      img: heroImage6,
      text: "Unfold Concetto Studio",
      subtext:
        "Effective design seamlessly integrates interior components and architectural intricacies that mirror your individuality and elevate your way of living.",
      subtext2: "",
    },
  ];

  // Blog Data
  const latestNewsData = [
    {
      image: latestNewsImage1,
      title: "Transforming Spaces",
      text: "The Art of Interior Design",
      date: "24 AUG 2023",
      subText1: (
        <div className='flex flex-col gap-[1rem]'>
          <p>
            Interior design is the art of enhancing the interior of a space to
            create a harmonious, functional, and aesthetically pleasing
            environment. It is a creative endeavor that combines elements of
            architecture, furniture, decor, and personal style to transform a
            blank canvas into a living masterpiece. In this blog, we'll explore
            the fascinating world of interior design and how it can breathe life
            into any space, whether it's a cozy home or a bustling office.
          </p>
          <h2 className='text-[22px]'>The Essence of Interior Design</h2>
          <p>
            Interior design is not just about arranging furniture and selecting
            colors; it's about understanding the inherent nature of a space and
            harnessing its potential. Designers must consider factors like the
            purpose of the space, the preferences of the occupants, and the
            architectural features when crafting their vision. This holistic
            approach ensures that the final result is not only visually
            appealing but also functional and comfortable.
          </p>
          <h2 className='text-[22px]'>Creating a Concept</h2>
          <p>
            Every successful interior design project begins with a concept. This
            concept serves as the guiding principle for all design decisions
            that follow. It can be inspired by a theme, a color palette, or even
            a feeling the designer wants to evoke. For example, a beachfront
            home might be designed with a coastal concept, featuring light
            colors, natural materials, and beach-inspired decor.
          </p>
          <h2 className='text-[22px]'>Space Planning</h2>
          <p>
            One of the fundamental aspects of interior design is space planning.
            Designers must determine how to arrange furniture and other elements
            to optimize the use of space. This involves considering traffic
            flow, focal points, and the size and shape of the room. Proper space
            planning ensures that the room is both functional and visually
            pleasing.
          </p>
          <h2 className='text-[22px]'>Color Palette and Materials</h2>
          <p>
            Choosing the right color palette and materials is crucial in
            interior design. Colors can set the mood and atmosphere of a space,
            while materials determine its texture and character. For instance,
            warm earthy tones and natural materials like wood and stone can
            create a cozy, rustic ambiance, while bold, vibrant colors and sleek
            materials like glass and metal can convey a modern, energetic vibe.
          </p>
          <h2 className='text-[22px]'>Furniture and Decor</h2>
          <p>
            Selecting the right furniture and decor is where a designer's
            creativity truly shines. The choice of furniture pieces, their
            style, and their arrangement can significantly impact the overall
            look and feel of a room. Decor items, such as artwork, lighting
            fixtures, and textiles, add the finishing touches and inject
            personality into the space.
          </p>
          <h2 className='text-[22px]'>Lighting Design</h2>
          <p>
            Lighting plays a crucial role in interior design, both functionally
            and aesthetically. Designers must consider various types of
            lighting, such as ambient, task, and accent lighting, to create the
            desired ambiance and functionality. Well-placed lighting can
            highlight architectural features, create focal points, and enhance
            the overall atmosphere.
          </p>
          <h2 className='text-[22px]'>Personalization</h2>
          <p>
            Interior design is a deeply personal endeavor, and the best designs
            reflect the personality and preferences of the occupants. Whether
            it's incorporating family heirlooms, showcasing a passion for art,
            or creating a serene oasis for relaxation, personalization makes a
            space truly unique and meaningful.
          </p>
          <h2 className='text-[22px]'>The Importance of Sustainability</h2>
          <p>
            In today's world, sustainable interior design is gaining prominence.
            Designers are increasingly incorporating eco-friendly materials,
            energy-efficient fixtures, and sustainable practices into their
            projects. This not only reduces the environmental footprint but also
            creates healthier and more sustainable living spaces.
          </p>
          <h2 className='text-[22px]'>Conclusion</h2>
          <p>
            Interior design is an art form that combines creativity,
            functionality, and personalization to transform spaces into
            captivating, harmonious environments. From conceptualization to
            space planning, color selection to lighting design, every aspect of
            interior design contributes to the creation of spaces that inspire,
            comfort, and reflect the unique personalities of their occupants.
            So, whether you're looking to revamp your home or create an inviting
            workspace, consider enlisting the expertise of an interior designer
            to turn your vision into reality.
          </p>
        </div>
      ),
    },
    {
      image: latestNewsImage2,
      title: "Elevate Your Well-Being with Unfold Concetto",
      text: "Your Path to Mindfulness and Inner Peace",
      date: "24 AUG 2023",
      subText1: (
        <div className='flex flex-col gap-[1rem]'>
          <p>
            In the hustle and bustle of modern life, finding moments of peace
            and clarity can feel like an elusive goal. The constant demands of
            work, family, and daily responsibilities can leave us feeling
            overwhelmed and disconnected. Enter Unfold Concetto – your trusted
            companion on the journey to mindfulness, inner peace, and
            well-being. In this blog, we'll explore the transformative services
            of Unfold Concetto and how they can help you lead a more balanced
            and harmonious life.
          </p>
          <h2 className='text-[22px]'>The Unfold Concetto Experience</h2>
          <p>
            Unfold Concetto is not just another mindfulness app; it's a
            comprehensive platform designed to cater to your well-being on
            multiple levels. Whether you're new to mindfulness or a seasoned
            practitioner, Unfold Concetto offers a rich array of features and
            services to support your journey.
          </p>

          <div className='px-[1rem] flex flex-col gap-[1rem]'>
            <p>
              • Guided Meditations: One of the core offerings of Unfold Concetto
              is its library of guided meditations. These meditations are
              crafted by experienced mindfulness instructors and cover a wide
              range of topics, from stress reduction and relaxation to personal
              growth and self-compassion. With Unfold Concetto, you have access
              to a vast resource of meditation sessions that can be tailored to
              your specific needs and preferences.
            </p>
            <p>
              • Personalized Programs: Unfold Concetto goes beyond individual
              meditations. It offers personalized mindfulness programs that are
              curated to address your unique well-being goals. Whether you seek
              relief from anxiety, improved focus, or a deeper sense of
              self-awareness, Unfold Concetto can create a program tailored just
              for you.
            </p>
            <p>
              • Daily Mindfulness Reminders: Developing a consistent mindfulness
              practice is made easier with Unfold Concetto's daily reminders.
              These gentle prompts encourage you to set aside time for self-care
              and mindfulness, even in the busiest of schedules.
            </p>
            <p>
              • Progress Tracking: The app provides tools for tracking your
              progress and reflecting on your mindfulness journey. Seeing your
              growth over time can be highly motivating and validating.
            </p>
            <p>
              • Community Support: Unfold Concetto offers a community feature
              that allows you to connect with like-minded individuals who share
              your mindfulness journey. You can exchange insights, experiences,
              and encouragement with a supportive community of users.
            </p>
          </div>
          <h2 className='text-[22px]'>Why Choose Unfold Concetto?</h2>
          <p>
            Unfold Concetto stands out in the crowded field of mindfulness apps
            for several reasons:
          </p>
          <div className='px-[1rem] flex flex-col gap-[1rem]'>
            <p>
              • Expert Guidance: All content on Unfold Concetto is created by
              experienced mindfulness instructors and mental health
              professionals. You can trust that you're receiving guidance from
              experts who understand the principles of mindfulness deeply.
            </p>
            <p>
              • Personalization: The app's ability to tailor programs and
              recommendations to your specific needs sets it apart. Your
              well-being is the priority, and Unfold Concetto ensures that your
              mindfulness practice aligns with your goals.
            </p>
            <p>
              • Convenience: With Unfold Concetto, mindfulness is at your
              fingertips whenever you need it. Whether you have a few minutes or
              an hour to spare, you can find a meditation or exercise that suits
              your timeframe.
            </p>
          </div>
          <h2 className='text-[22px]'>Conclusion</h2>
          <p>
            In a world where stress and distractions are ever-present,
            prioritizing your mental well-being is essential. Unfold Concetto
            offers a holistic approach to mindfulness, providing you with the
            tools and support you need to cultivate inner peace, reduce stress,
            and lead a more balanced and fulfilling life.
          </p>
          <p>
            Don't wait to embark on your journey to mindfulness and well-being.
            Download Unfold Concetto today and discover how its transformative
            services can help you unlock your inner peace and elevate your
            overall quality of life. It's time to unfold the path to a more
            mindful and harmonious existence with Unfold Concetto.
          </p>
        </div>
      ),
    },
    {
      image: latestNewsImage3,
      title: "Elevate Your Interior Design Journey with Unfold Concetto",
      text: "Twin Forestland Home",
      date: "24 AUG 2023",
      subText1: (
        <div className='flex flex-col gap-[1rem]'>
          <p>
            Interior design is an art form that transforms spaces into stunning
            and functional works of art. Whether you're revamping your home or
            designing a new office space, the right interior design can make all
            the difference. But where do you begin? How can you ensure that your
            space reflects your unique style and needs? The answer lies in
            Unfold Concetto—a revolutionary service that takes your interior
            design journey to new heights. In this blog, we'll explore the
            incredible services of Unfold Concetto and how they can help you
            create the space of your dreams.
          </p>
          <h2 className='text-[22px]'>The Power of Interior Design</h2>
          <p>
            Interior design is about more than just aesthetics; it's about
            creating environments that enhance your quality of life. A
            well-designed space can inspire creativity, boost productivity, and
            provide comfort and solace. It's a delicate balance of functionality
            and beauty, and it requires careful planning and execution.
          </p>
          <h2 className='text-[22px]'>
            Unfold Concetto: Your Interior Design Companion
          </h2>
          <p>
            Unfold Concetto understands the challenges that come with interior
            design projects, whether big or small. That's why it offers a
            comprehensive suite of services to support you at every stage of
            your design journey.
          </p>
          <div className='px-[1rem] flex flex-col gap-[1rem]'>
            <p>
              • Personalized Consultations: The foundation of every successful
              interior design project is a deep understanding of your needs and
              preferences. Unfold Concetto begins by offering personalized
              consultations to grasp your vision, budget, and timeline. This
              ensures that the design aligns perfectly with your desires.
            </p>
            <p>
              • Conceptualization and Planning: Unfold Concetto's team of
              experienced designers works closely with you to create a design
              concept that resonates with your style. They take into account
              every aspect, from color palettes and furniture choices to layout
              and lighting, to bring your vision to life.
            </p>
            <p>
              • Visualization: Visualizing the final design can be challenging,
              but Unfold Concetto uses cutting-edge technology to provide you
              with 3D renderings and mood boards. These tools allow you to see
              how the design will look and make adjustments before the work
              begins.
            </p>
            <p>
              • Sourcing and Procurement: Finding the right furniture, decor,
              and materials can be a daunting task. Unfold Concetto takes the
              burden off your shoulders by sourcing and procuring all the
              necessary items, ensuring they meet the highest quality standards.
            </p>
            <p>
              • Project Management: Coordinating contractors, timelines, and
              budgets is a complex endeavor. Unfold Concetto handles the project
              management, ensuring that everything runs smoothly and on
              schedule.
            </p>
            <p>
              • Personalized Styling: The finishing touches are what truly make
              a space come to life. Unfold Concetto's designers are experts at
              selecting and arranging decor items, artwork, and textiles to
              personalize your space and make it uniquely yours.
            </p>
            <p>
              • Post-Project Support: Unfold Concetto doesn't stop once the
              project is completed. They provide ongoing support to ensure your
              satisfaction and address any post-project needs or concerns.
            </p>
          </div>
          <h2 className='text-[22px]'>Why Choose Unfold Concetto?</h2>
          <p>
            Unfold Concetto sets itself apart in the world of interior design
            services in several ways:
          </p>
          <div className='px-[1rem] flex flex-col gap-[1rem]'>
            <p>
              • Personalization: Every project is tailored to your unique needs
              and style preferences. Unfold Concetto's commitment to
              personalization ensures that your space reflects your personality
              and lifestyle.
            </p>
            <p>
              • Expertise: The team at Unfold Concetto comprises seasoned
              interior designers with a wealth of experience. Their expertise
              translates into designs that are not only beautiful but also
              functional and practical.
            </p>
            <p>
              • Simplicity: Interior design projects can be overwhelming, but
              Unfold Concetto streamlines the process, making it accessible and
              straightforward for you. They handle the details so that you can
              enjoy the creative journey.
            </p>
          </div>
          <h2 className='text-[22px]'>Conclusion</h2>
          <p>
            Interior design is about creating spaces that elevate your quality
            of life and express your personality. With Unfold Concetto by your
            side, your interior design dreams can become a reality. From
            conceptualization and planning to project management and
            personalized styling, Unfold Concetto offers a complete suite of
            services that will transform your space into a masterpiece. Don't
            wait to embark on your interior design journey. Contact Unfold
            Concetto today and experience the power of exceptional design. It's
            time to unfold the beauty of your space with Unfold Concetto.
          </p>
        </div>
      ),
    },
  ];

  // Project Data
  const ourProjectData = {
    all: [
      {
        image: project3DVisualizationProject1Image1,
        pageImage1: project3DVisualizationProject1MainImage,
        pageImage2: project3DVisualizationProject1Image1,
        pageImage3: project3DVisualizationProject1Image2,
        heading: "Resident Bedroom",
        subtext: "Semi Open Sitting Area",
        subtext2:
          "Villa gravida eros ut turpis interdum ornare. Interdum et malesu they adamale fames ac anteip pimsine faucibus. Curabitur arcu site feugiat in torto.",
      },
      {
        image: project3DVisualizationProject2Image1,
        pageImage1: project3DVisualizationProject2MainImage,
        pageImage2: project3DVisualizationProject2Image1,
        pageImage3: project3DVisualizationProject2Image2,
        heading: "Rahul Bhai Dressings (2022)",
        subtext: "Dressing",
        subtext2:
          "Villa gravida eros ut turpis interdum ornare. Interdum et malesu they adamale fames ac anteip pimsine faucibus. Curabitur arcu site feugiat in torto.",
      },
      {
        image: projectArchitectureDesignProject1Image1,
        pageImage1: projectArchitectureDesignProject1MainImage,
        pageImage2: projectArchitectureDesignProject1Image1,
        pageImage3: projectArchitectureDesignProject1Image2,
        heading: "Mayur Bhai Cafe (2020)",
        subtext: "Outdoor cafa on road side",
        subtext2:
          "Villa gravida eros ut turpis interdum ornare. Interdum et malesu they adamale fames ac anteip pimsine faucibus. Curabitur arcu site feugiat in torto.",
      },
      {
        image: projectArchitectureDesignProject2Image1,
        pageImage1: projectArchitectureDesignProject2MainImage,
        pageImage2: projectArchitectureDesignProject2Image1,
        pageImage3: projectArchitectureDesignProject2Image2,
        heading: "Royal Bungalows (2021)",
        subtext: "Site Office",
        subtext2:
          "Villa gravida eros ut turpis interdum ornare. Interdum et malesu they adamale fames ac anteip pimsine faucibus. Curabitur arcu site feugiat in torto.",
      },
      {
        image: projectArchitectureDesignProject3Image1,
        pageImage1: projectArchitectureDesignProject3MainImage,
        pageImage2: projectArchitectureDesignProject3Image1,
        pageImage3: projectArchitectureDesignProject3Image2,
        heading: "Indian institute of Skill (IISs)",
        subtext: "Institute Concept",
        subtext2:
          "Villa gravida eros ut turpis interdum ornare. Interdum et malesu they adamale fames ac anteip pimsine faucibus. Curabitur arcu site feugiat in torto.",
      },
      {
        image: projectArchitectureDesignProject4Image1,
        pageImage1: projectArchitectureDesignProject4MainImage,
        pageImage2: projectArchitectureDesignProject4Image1,
        pageImage3: projectArchitectureDesignProject4Image2,
        heading: "Sankat (2022)",
        subtext: "Semi open sitting area",
        subtext2:
          "Villa gravida eros ut turpis interdum ornare. Interdum et malesu they adamale fames ac anteip pimsine faucibus. Curabitur arcu site feugiat in torto.",
      },
      {
        image: projectInteriorDesignProject1Image1,
        pageImage1: projectInteriorDesignProject1MainImage,
        pageImage2: projectInteriorDesignProject1Image1,
        pageImage3: projectInteriorDesignProject1Image2,
        heading: "Bhagwati Spring (2023)",
        subtext: "Office",
        subtext2:
          "Villa gravida eros ut turpis interdum ornare. Interdum et malesu they adamale fames ac anteip pimsine faucibus. Curabitur arcu site feugiat in torto.",
      },
      {
        image: projectInteriorDesignProject2Image1,
        pageImage1: projectInteriorDesignProject2MainImage,
        pageImage2: projectInteriorDesignProject2Image1,
        pageImage3: projectInteriorDesignProject2Image2,
        heading: "Tanmay Bhai (2023)",
        subtext: "2bhk design",
        subtext2:
          "Villa gravida eros ut turpis interdum ornare. Interdum et malesu they adamale fames ac anteip pimsine faucibus. Curabitur arcu site feugiat in torto.",
      },
      {
        image: projectInteriorDesignProject3Image1,
        pageImage1: projectInteriorDesignProject3MainImage,
        pageImage2: projectInteriorDesignProject3Image1,
        pageImage3: projectInteriorDesignProject3Image2,
        heading: "Chirag Patel (2023)",
        subtext: "1 bhk design",
        subtext2:
          "Villa gravida eros ut turpis interdum ornare. Interdum et malesu they adamale fames ac anteip pimsine faucibus. Curabitur arcu site feugiat in torto.",
      },
      {
        image: projectInteriorDesignProject4Image1,
        pageImage1: projectInteriorDesignProject4MainImage,
        pageImage2: projectInteriorDesignProject4Image1,
        pageImage3: projectInteriorDesignProject4Image2,
        heading: "Entrepreneur Inside Out (2020)",
        subtext: "Office interior",
        subtext2:
          "Villa gravida eros ut turpis interdum ornare. Interdum et malesu they adamale fames ac anteip pimsine faucibus. Curabitur arcu site feugiat in torto.",
      },
      {
        image: projectInteriorDesignProject5Image1,
        pageImage1: projectInteriorDesignProject5MainImage,
        pageImage2: projectInteriorDesignProject5Image1,
        pageImage3: projectInteriorDesignProject5Image2,
        heading: "Shaki House (2021)",
        subtext: "Living and 1bhk small house",
        subtext2:
          "Villa gravida eros ut turpis interdum ornare. Interdum et malesu they adamale fames ac anteip pimsine faucibus. Curabitur arcu site feugiat in torto.",
      },
      {
        image: projectInteriorDesignProject6Image1,
        pageImage1: projectInteriorDesignProject6MainImage,
        pageImage2: projectInteriorDesignProject6Image1,
        pageImage3: projectInteriorDesignProject6Image2,
        heading: "Pream Bhai (2022)",
        subtext: "3 Living Room 1bhk apartment",
        subtext2:
          "Villa gravida eros ut turpis interdum ornare. Interdum et malesu they adamale fames ac anteip pimsine faucibus. Curabitur arcu site feugiat in torto.",
      },
      {
        image: projectInteriorDesignProject7Image1,
        pageImage1: projectInteriorDesignProject7MainImage,
        pageImage2: projectInteriorDesignProject7Image1,
        pageImage3: projectInteriorDesignProject7Image2,
        heading: "Shivam house (2022)",
        subtext: "3 Living Dining Kitchen 2 bhk with classic design theam",
        subtext2:
          "Villa gravida eros ut turpis interdum ornare. Interdum et malesu they adamale fames ac anteip pimsine faucibus. Curabitur arcu site feugiat in torto.",
      },
    ],
    landscape: [
      // {
      //   image: ourProjectImage1,
      //   pageImage1: projectPageMainImage,
      //   pageImage2: projectPageImage1,
      //   pageImage3: projectPageImage2,
      //   heading: "Shakti House",
      //   subtext: "1bhk small house",
      //   subtext2:
      //     "Villa gravida eros ut turpis interdum ornare. Interdum et malesu they adamale fames ac anteip pimsine faucibus. Curabitur arcu site feugiat in torto.",
      // },
      // {
      //   image: ourProjectImage2,
      //   pageImage1: projectPageMainImage,
      //   pageImage2: projectPageImage1,
      //   pageImage3: projectPageImage2,
      //   heading: "Tanmay Bhai Foyar",
      //   subtext: "2bhk design",
      //   subtext2:
      //     "Villa gravida eros ut turpis interdum ornare. Interdum et malesu they adamale fames ac anteip pimsine faucibus. Curabitur arcu site feugiat in torto.",
      // },
    ],
    interiorDesign: [
      {
        image: projectInteriorDesignProject1Image1,
        pageImage1: projectInteriorDesignProject1MainImage,
        pageImage2: projectInteriorDesignProject1Image1,
        pageImage3: projectInteriorDesignProject1Image2,
        heading: "Bhagwati Spring (2023)",
        subtext: "Office",
        subtext2:
          "Villa gravida eros ut turpis interdum ornare. Interdum et malesu they adamale fames ac anteip pimsine faucibus. Curabitur arcu site feugiat in torto.",
      },
      {
        image: projectInteriorDesignProject2Image1,
        pageImage1: projectInteriorDesignProject2MainImage,
        pageImage2: projectInteriorDesignProject2Image1,
        pageImage3: projectInteriorDesignProject2Image2,
        heading: "Tanmay Bhai (2023)",
        subtext: "2bhk design",
        subtext2:
          "Villa gravida eros ut turpis interdum ornare. Interdum et malesu they adamale fames ac anteip pimsine faucibus. Curabitur arcu site feugiat in torto.",
      },
      {
        image: projectInteriorDesignProject3Image1,
        pageImage1: projectInteriorDesignProject3MainImage,
        pageImage2: projectInteriorDesignProject3Image1,
        pageImage3: projectInteriorDesignProject3Image2,
        heading: "Chirag Patel (2023)",
        subtext: "1 bhk design",
        subtext2:
          "Villa gravida eros ut turpis interdum ornare. Interdum et malesu they adamale fames ac anteip pimsine faucibus. Curabitur arcu site feugiat in torto.",
      },
      {
        image: projectInteriorDesignProject4Image1,
        pageImage1: projectInteriorDesignProject4MainImage,
        pageImage2: projectInteriorDesignProject4Image1,
        pageImage3: projectInteriorDesignProject4Image2,
        heading: "Entrepreneur Inside Out (2020)",
        subtext: "Office interior",
        subtext2:
          "Villa gravida eros ut turpis interdum ornare. Interdum et malesu they adamale fames ac anteip pimsine faucibus. Curabitur arcu site feugiat in torto.",
      },
      {
        image: projectInteriorDesignProject5Image1,
        pageImage1: projectInteriorDesignProject5MainImage,
        pageImage2: projectInteriorDesignProject5Image1,
        pageImage3: projectInteriorDesignProject5Image2,
        heading: "Shaki House (2021)",
        subtext: "Living and 1bhk small house",
        subtext2:
          "Villa gravida eros ut turpis interdum ornare. Interdum et malesu they adamale fames ac anteip pimsine faucibus. Curabitur arcu site feugiat in torto.",
      },
      {
        image: projectInteriorDesignProject6Image1,
        pageImage1: projectInteriorDesignProject6MainImage,
        pageImage2: projectInteriorDesignProject6Image1,
        pageImage3: projectInteriorDesignProject6Image2,
        heading: "Pream Bhai (2022)",
        subtext: "3 Living Room 1bhk apartment",
        subtext2:
          "Villa gravida eros ut turpis interdum ornare. Interdum et malesu they adamale fames ac anteip pimsine faucibus. Curabitur arcu site feugiat in torto.",
      },
      {
        image: projectInteriorDesignProject7Image1,
        pageImage1: projectInteriorDesignProject7MainImage,
        pageImage2: projectInteriorDesignProject7Image1,
        pageImage3: projectInteriorDesignProject7Image2,
        heading: "Shivam house (2022)",
        subtext: "3 Living Dining Kitchen 2 bhk with classic design theam",
        subtext2:
          "Villa gravida eros ut turpis interdum ornare. Interdum et malesu they adamale fames ac anteip pimsine faucibus. Curabitur arcu site feugiat in torto.",
      },
    ],
    architecture: [
      {
        image: projectArchitectureDesignProject1Image1,
        pageImage1: projectArchitectureDesignProject1MainImage,
        pageImage2: projectArchitectureDesignProject1Image1,
        pageImage3: projectArchitectureDesignProject1Image2,
        heading: "Mayur Bhai Cafe (2020)",
        subtext: "Outdoor cafa on road side",
        subtext2:
          "Villa gravida eros ut turpis interdum ornare. Interdum et malesu they adamale fames ac anteip pimsine faucibus. Curabitur arcu site feugiat in torto.",
      },
      {
        image: projectArchitectureDesignProject2Image1,
        pageImage1: projectArchitectureDesignProject2MainImage,
        pageImage2: projectArchitectureDesignProject2Image1,
        pageImage3: projectArchitectureDesignProject2Image2,
        heading: "Royal Bungalows (2021)",
        subtext: "Site Office",
        subtext2:
          "Villa gravida eros ut turpis interdum ornare. Interdum et malesu they adamale fames ac anteip pimsine faucibus. Curabitur arcu site feugiat in torto.",
      },
      {
        image: projectArchitectureDesignProject3Image1,
        pageImage1: projectArchitectureDesignProject3MainImage,
        pageImage2: projectArchitectureDesignProject3Image1,
        pageImage3: projectArchitectureDesignProject3Image2,
        heading: "Indian institute of Skill (IISs)",
        subtext: "Institute Concept",
        subtext2:
          "Villa gravida eros ut turpis interdum ornare. Interdum et malesu they adamale fames ac anteip pimsine faucibus. Curabitur arcu site feugiat in torto.",
      },
      {
        image: projectArchitectureDesignProject4Image1,
        pageImage1: projectArchitectureDesignProject4MainImage,
        pageImage2: projectArchitectureDesignProject4Image1,
        pageImage3: projectArchitectureDesignProject4Image2,
        heading: "Sankat (2022)",
        subtext: "Semi open sitting area",
        subtext2:
          "Villa gravida eros ut turpis interdum ornare. Interdum et malesu they adamale fames ac anteip pimsine faucibus. Curabitur arcu site feugiat in torto.",
      },
    ],
    threeDVisualization: [
      {
        image: project3DVisualizationProject1Image1,
        pageImage1: project3DVisualizationProject1MainImage,
        pageImage2: project3DVisualizationProject1Image1,
        pageImage3: project3DVisualizationProject1Image2,
        heading: "Resident Bedroom",
        subtext: "Semi Open Sitting Area",
        subtext2:
          "Villa gravida eros ut turpis interdum ornare. Interdum et malesu they adamale fames ac anteip pimsine faucibus. Curabitur arcu site feugiat in torto.",
      },
      {
        image: project3DVisualizationProject2Image1,
        pageImage1: project3DVisualizationProject2MainImage,
        pageImage2: project3DVisualizationProject2Image1,
        pageImage3: project3DVisualizationProject2Image2,
        heading: "Rahul Bhai Dressings (2022)",
        subtext: "Dressiing",
        subtext2:
          "Villa gravida eros ut turpis interdum ornare. Interdum et malesu they adamale fames ac anteip pimsine faucibus. Curabitur arcu site feugiat in torto.",
      },
    ],
  };

  // Services Data
  const servicesData = [
    {
      image: projectArchitectureDesignProject2Image1,
      pageImage1: projectArchitectureDesignProject2MainImage,
      pageImage2: projectArchitectureDesignProject2Image2,
      pageImage3: projectArchitectureDesignProject4Image1,
      serviceName: "Architecture",
      text: (
        <p className='text-[12px] text-justify'>
          Drawing upon conceptual designs, sketches, hand drawings, and
          conceptual models, Unfold Concetto Studio consistently delivers
          enhanced design services as the project progresses from the initial
          concept phase to the construction documentation stage. Our team,
          comprised of architects, excels in transforming designers' ideas
          conveyed through diverse levels of input (2D or 3D) during the initial
          conceptual phases into a set of construction-ready building documents.
        </p>
      ),
      pageText: (
        <div className='flex flex-col gap-[1rem]'>
          <p>
            As a design-focused company, we harmonize the technical aspects of
            interior designing, innovative issue-solving, and the artistic
            elements of architecture, championing the principles of genuine,
            modern architecture. Unfold Concetto Studio channels its efforts
            towards attaining the utmost levels of design excellence.
          </p>
          <p>
            Within our architectural design agency, dedicated teams are poised
            to deliver precise modeling and sculpting of spaces, guided by the
            influence of natural elegance. This approach will bestow a tranquil
            essence upon your architectural design firm, resonating through
            every dimension.
          </p>
        </div>
      ),
      link: "",
    },
    {
      image: projectSitePicturesImage1,
      pageImage1: projectSitePicturesMainImage,
      pageImage2: projectSitePicturesImage1,
      pageImage3: projectSitePicturesImage2,
      serviceName: "Site Pictures (Upcoming & On-Going)",
      text: (
        <div className='flex flex-col gap-[10px] text-[12px] text-justify'>
          <p>
            The significance of transforming our urban areas into green spaces
            has never been more crucial, especially in light of overarching
            issues like urbanization and the depletion of habitats. This context
            has led us to embrace a dedicated approach to our interaction with
            natural ecosystems in all our endeavors. Landscape Planner play a
            pivotal role in addressing these challenges, fueling our enthusiasm
            for crafting impactful experiences that resonate with a diverse
            spectrum of individuals.
          </p>
          <p>
            Landscape architects at Unfold Concetto Studio considerate,
            eco-friendly, and acclaimed landscape designs. Our team is engaged
            in a wide array of successful projects across various sizes and
            locations, all recognized and highly acclaimed.
          </p>
        </div>
      ),
      pageText: (
        <div className='flex flex-col gap-[1rem]'>
          <p>
            Modern landscape design faces numerous urgent global issues such as
            urbanization, climate change, and the decline of habitats. Our
            methodology places a paramount emphasis on creating inviting and
            stimulating spaces. Equally vital are strategies that contribute to
            the creation of livable urban areas, guarantee a net gain in
            biodiversity, enhance climate change resilience, and promote
            improved resource utilization.
          </p>
          <p>
            Our design concepts are fueled by a conscientious assessment of
            their potential impact on the quality of life and the robustness of
            our surroundings. Additionally, we employ a comprehensive technical
            grasp of interlinked systems involving water, ecology, energy, and
            waste management.
          </p>
        </div>
      ),
      link: "",
    },
    {
      image: project3DVisualizationProject2Image1,
      pageImage1: project3DVisualizationProject1MainImage,
      pageImage2: project3DVisualizationProject1Image1,
      pageImage3: project3DVisualizationProject2Image2,
      serviceName: "3D Visualization",
      text: (
        <p className='text-[12px] text-justify'>
          Architect-designed 3D interior and exterior designs are in great
          demand owing to their stunning aesthetics. The prevalence of 3D
          interior and exterior design is steadily rising. Essentially, 3D
          interior and exterior design process involves creating lifelike models
          for the architectural field to produce photorealistic visuals.
          Architects commonly start new projects by crafting a 3D plan,
          enhancing their ability to visualize the interior and exterior models
          more effectively.
        </p>
      ),
      pageText: (
        <div className='flex flex-col gap-[1rem]'>
          <p>
            The utilization of 3D interior and exterior design elevates the
            quality of home interiors by offering intricate visualizations of
            the arrangement and authentic representations of the spatial
            aesthetics. Through 3D interior and exterior design, homeowners can
            acquire a more profound comprehension of the space, its potential
            utility, and the eventual appearance of the completed project. This
            approach furnishes a comprehensive panorama of the area,
            encompassing furniture arrangement, virtual flooring, wall colors,
            illumination, curtains, and various other particulars.
          </p>
        </div>
      ),
      link: "",
    },
    {
      image: projectInteriorDesignProject2Image1,
      pageImage1: projectInteriorDesignProject1MainImage,
      pageImage2: projectInteriorDesignProject3Image1,
      pageImage3: projectInteriorDesignProject3Image2,
      serviceName: "Interior Design",
      text: (
        <div className='flex flex-col gap-[10px] text-[12px] text-justify'>
          <p>
            To achieve a contemporary design, it's essential to enlist the
            expertise of our interior designers. They possess the proficiency to
            provide you with the latest trends that are currently fashionable.
          </p>
          <p>
            Unfold Concetto Studio is a residential & commercial interior design
            firm, and its team of skilled and top-notch interior designers
            consistently stays informed about the latest trends in interior
            design services.
          </p>
        </div>
      ),
      pageText: (
        <div className='flex flex-col gap-[1rem]'>
          <p>
            Home & Commercial interior trends evolve periodically, encompassing
            various styles and themes. Our team of exceptional interior
            designers specializes in crafting innovative interior patterns for
            contemporary homes and working places. Their expertise will elevate
            the appearance of your residence and commercial establishments,
            leaving a lasting impression on your guests. Our interior design
            services offer a modern transformation that will undoubtedly earn
            admiration for your refined tastes from all who visit your home or
            commercial space.
          </p>
        </div>
      ),
      link: "",
    },
  ];

  return (
    <BrowserRouter>
      <div className='App'>
        <Header />

        <Routes>
          <Route
            element={
              <Homepage
                latestNewsData={latestNewsData}
                ourProjectData={ourProjectData}
                heroCarouselData={heroCarouselData}
              />
            }
            path={"/"}
          />
          <Route element={<AboutUs />} path={"/AboutUs"} />
          <Route
            element={<Services servicesData={servicesData} />}
            path={"/Services"}
          />
          <Route
            element={<Projects ourProjectData={ourProjectData} />}
            path={"/Projects"}
          />
          <Route
            element={<Blog latestNewsData={latestNewsData} />}
            path={"/Blogs"}
          />
          <Route element={<ContactUs />} path={"/ContactUs"} />

          {/* Inner Pages */}
          <Route
            element={<SingleBlogPage latestNewsData={latestNewsData} />}
            path={"/Blogs/:SingleBlogId"}
          />
          <Route
            element={<SingleProjectPage ourProjectData={ourProjectData} />}
            path={"/Projects/:SingleProjectPageId"}
          />
          <Route
            element={<SingleServicePage servicesData={servicesData} />}
            path={"/Services/:SingleServicePageId"}
          />
        </Routes>

        <Footer />
      </div>
    </BrowserRouter>
  );
}
