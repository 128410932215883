import React, { useEffect } from "react";
import "./ContactUs.css";

import ContactUsHeroImage from "../../assets/contactUs/contactHeroImage.jpg";
import HeroSectionOfPages from "../../components/heroSectionOfPages/HeroSectionOfPages";

import ContactComp from "../../components/contactComp/ContactComp";

import ContactImage from "../../assets/contactBGImg.jpg";
import ContactForm from "../../components/contactForm/ContactForm";

import { useLocation } from "react-router-dom";
import { useContext } from "react";
import ApiContext from "../../context/Api";

export default function ContactUs() {
  const location = useLocation();

  const { setCurrentPath } = useContext(ApiContext);

  useEffect(() => {
    setCurrentPath(location.pathname);
  });

  return (
    <div className='contactUs-section'>
      <HeroSectionOfPages
        image={ContactUsHeroImage}
        heading={"Contact Us"}
        link={"/"}
      />

      <div className='contactUs-section-main text-white flex flex-row justify-between px-[4rem] py-[3rem]'>
        <div className='contactUs-section-main-left flex flex-col gap-[2rem] w-[50%]'>
          <div className='flex flex-col gap-[10px]'>
            <h2 className='text-[25px]'>LET'S START A PROJECT</h2>
            <p>Say something to start a live chat!</p>
          </div>
          <p>
            Give us a call or drop by anytime, we endeavour to answer all
            enquiries within 24 hours on business days. We will be happy to
            answer your questions.
          </p>
          <div className='flex flex-col gap-[8px]'>
            {/* <p>VAT : USA002323065B06</p> */}
            <a href='tel:+919898509124'>Phone : +91 98985 09124</a>
            <a href='mailto:hello@unfoldconcetto.in'>
              Email : hello@unfoldconcetto.in
            </a>
            <p>
              Address : Shree Shakti Society behind Avishkar Complex Old Padra
              Road, 12/A , Vadodara, Gujarat 390020, IN
            </p>
          </div>
        </div>

        <div className='contactUs-section-main-right w-[50%] flex flex-col'>
          <ContactForm />
        </div>
      </div>

      <div className='aboutus-section-contact relative'>
        <img className='' src={ContactImage} alt='ContactImage' />
        <div className='absolute top-0 w-full h-full'>
          <ContactComp />
        </div>
      </div>
    </div>
  );
}
