import React, { useEffect } from "react";
import "./SingleBlogPage.css";

import singleBlogHeroImage from "../../../assets/blog/singleBlog/singleBlogHeroImage.jpg";
import HeroSectionOfPages from "../../../components/heroSectionOfPages/HeroSectionOfPages";

// import singleBlogMainImage from "../../../assets/blog/singleBlog/singleBlogMainImage.jpg";
import ContactForm from "../../../components/contactForm/ContactForm";

import ContactImage from "../../../assets/contactBGImg.jpg";

import ContactComp from "../../../components/contactComp/ContactComp";

import { useLocation } from "react-router-dom";
import { useContext } from "react";
import ApiContext from "../../../context/Api";

import { useParams } from "react-router-dom";

export default function SingleBlogPage({ latestNewsData }) {
  const location = useLocation();

  const { SingleBlogId } = useParams();

  const blogData = latestNewsData.find((data) => {
    return data.title === SingleBlogId;
  });

  console.log(blogData);

  const { setCurrentPath } = useContext(ApiContext);

  useEffect(() => {
    setCurrentPath(location.pathname);
  });

  return (
    <div className='singleBlogPage-section'>
      <HeroSectionOfPages
        image={singleBlogHeroImage}
        heading={blogData.title}
        link={"/"}
      />

      <div className='singleBlogPage-section-main p-[2rem]'>
        <div className='singleBlogPage-section-main-image relative'>
          <img
            src={blogData.image}
            className='rounded-[5px] w-full h-[500px]'
            alt='singleBlogMainImage'
          />
          <div className='singleBlogPage-section-main-image-text absolute top-0 px-[6rem] py-[2rem] flex flex-col gap-[1rem]'>
            <h2 className='text-[25px] text-[#FF965A]'>{blogData.title}</h2>
            <p className='text-white'>{blogData.text}</p>
          </div>
        </div>
      </div>

      <div className='singleBlogPage-section-main-textpart flex flex-col gap-[1rem] text-white px-[4rem] py-[2rem]'>
        <p className='text-[25px]'>{blogData.title}</p>
        {/* <p>{`| ${blogData.date}`}</p> */}

        <p>{blogData.subText1}</p>

        {/* <p>{blogData.subText2}</p>

        <p>{blogData.subText3}</p> */}
      </div>

      <div className='page-Contact-form flex flex-col gap-[1rem] px-[4rem] py-[2rem] text-white'>
        <p>Leave a Reply</p>
        <ContactForm />
      </div>

      <div className='aboutus-section-contact relative'>
        <img className='' src={ContactImage} alt='ContactImage' />
        <div className='absolute top-0 w-full h-full'>
          <ContactComp />
        </div>
      </div>
    </div>
  );
}
