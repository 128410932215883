import React from "react";
import "./CreativeTeam.css";

import creativeTeamaboutBG from "../../assets/aboutus/aboutBG.png";
// import creativeTeamImage1 from "../../assets/aboutus/creativeteam/1.jpg";
// import creativeTeamImage2 from "../../assets/aboutus/creativeteam/2.jpg";
// import creativeTeamImage3 from "../../assets/aboutus/creativeteam/3.jpg";
// import creativeTeamImage4 from "../../assets/aboutus/creativeteam/4.jpg";
// import creativeTeamImage5 from "../../assets/aboutus/creativeteam/5.jpg";
// import creativeTeamImage6 from "../../assets/aboutus/creativeteam/6.jpg";

import photo1 from "../../assets/aboutus/photo.jpg";
import photo2 from "../../assets/aboutus/photo2.jpeg";

import { BsWhatsapp, BsLinkedin, BsInstagram } from "react-icons/bs";

export default function CreativeTeam() {
  const creativeTeamData = [
    {
      image: photo1,
      name: (
        <div className='flex flex-col gap-[8px]'>
          <h4 className='text-[22px]'>Aaditya Pathak</h4>
          <p className='text-[16px]'>Managing Director and Partner</p>
        </div>
      ),
      text: (
        <div className='flex flex-col gap-[8px]'>
          <p className='text-[12px]'>
            With a solid six-year work history, an MBA in Finance from Swami
            Vivekanand Subharti University, and a B.Com in Accountancy from
            Maharaja Sayajirao University, I bring valuable expertise to the
            table. I thrive in dynamic settings, leading teams efficiently, and
            making sound decisions under pressure. My meticulous attention to
            detail ensures precision in every task. Committed to continuous
            growth, I'm eager to contribute my skills to our organization's
            success. Let's embark on a journey of innovation and achievement
            together, shaping a brighter future for our company and its
            stakeholders.
          </p>
        </div>
      ),
      whatsapp: "https://wa.me/+919898509124",
      linkedin: "https://www.linkedin.com/in/aaditya-pathak-1ba568193/",
      instagram: "https://www.instagram.com/aaditya_2795/",
    },
    {
      image: photo2,
      name: (
        <div className='flex flex-col gap-[8px]'>
          <h4 className='text-[22px]'>Ar. Adarsh Chaturvedi</h4>
          <p className='text-[16px]'>
            Managing Director and Partner (Head Architect)
          </p>
        </div>
      ),
      text: (
        <div className='flex flex-col gap-[8px]'>
          <p className='text-[12px]'>
            At the core of my professional philosophy lies a deep commitment to
            the principles of continuous learning and research within the realm
            of architecture. My unwavering goal is to constantly enhance my
            skills and knowledge, all while delivering exceptional, fulfilling
            architectural work.{" "}
          </p>
          <p className='text-[12px]'>
            My journey began as a freelance architect, where I successfully
            completed significant projects encompassing planning, interior
            design, and 3D modelling. Currently, I serve as the Managing
            Director and Partner at Unfold Concetto, where I continue to push
            the boundaries of architectural innovation and excellence. Our
            mission is to blend creativity and functionality to craft spaces
            that inspire and delight. We are committed to bringing your
            architectural visions to life while pushing the envelope of design
            possibilities. Join us on this exciting journey as we shape the
            future of architecture together.
          </p>
        </div>
      ),
      whatsapp: "https://wa.me/+919898509124",
      linkedin: "https://www.linkedin.com/in/ar-aadarsh-chaturvedi-58213a17b/ ",
      instagram: "https://www.instagram.com/a.adarsh14/",
    },
    // {
    //   image: creativeTeamImage3,
    //   name: "Robert Brown / dipl. Arch ETH/SIA",
    //   text: "Viverra tristique usto duis vitae diam neque sivamus aesta vazio artines aten",
    //   whatsapp: "",
    //   facebook: "",
    //   instagram: "",
    // },
    // {
    //   image: creativeTeamImage4,
    //   name: "Robert Brown / dipl. Arch ETH/SIA",
    //   text: "Viverra tristique usto duis vitae diam neque sivamus aesta vazio artines aten",
    //   whatsapp: "",
    //   facebook: "",
    //   instagram: "",
    // },
    // {
    //   image: creativeTeamImage5,
    //   name: "Robert Brown / dipl. Arch ETH/SIA",
    //   text: "Viverra tristique usto duis vitae diam neque sivamus aesta vazio artines aten",
    //   whatsapp: "",
    //   facebook: "",
    //   instagram: "",
    // },
    // {
    //   image: creativeTeamImage6,
    //   name: "Robert Brown / dipl. Arch ETH/SIA",
    //   text: "Viverra tristique usto duis vitae diam neque sivamus aesta vazio artines aten",
    //   whatsapp: "",
    //   facebook: "",
    //   instagram: "",
    // },
  ];

  const renderedCreativeTeamData = creativeTeamData.map((data, index) => {
    return (
      <div
        key={index}
        className='creativeTeam-card flex flex-col-reverse justify-between border rounded-[6px] items-center p-[1rem] gap-[1rem] max-md:h-fit'>
        <div className='flex flex-col gap-[10px] w-full h-[50%] max-md:h-[75%]'>
          <h4 className='text-[22px] max-md:text-[20px]'>{data.name}</h4>
          <p>{data.text}</p>
          <div className='creativeTeam-card-icons flex flex-row gap-[10px]'>
            <a
              href={data.whatsapp}
              target='_blank'
              rel='noreferrer'
              className='border p-[10px] rounded-[4px] cursor-pointer'>
              <BsWhatsapp />
            </a>
            <a
              href={data.linkedin}
              target='_blank'
              rel='noreferrer'
              className='border p-[10px] rounded-[4px] cursor-pointer'>
              <BsLinkedin />
            </a>
            <a
              href={data.instagram}
              target='_blank'
              rel='noreferrer'
              className='border p-[10px] rounded-[4px] cursor-pointer'>
              <BsInstagram />
            </a>
          </div>
        </div>
        <img
          className='w-[350px] h-[50%] max-md:h-full'
          src={data.image}
          alt={`${data.name}${index}`}
        />
      </div>
    );
  });
  return (
    <div className='aboutus-section-creativeTeam-section relative flex justify-center'>
      <img
        className='w-[50%]'
        src={creativeTeamaboutBG}
        alt='creativeTeamaboutBG'
      />
      <div className='aboutus-section-creativeTeam-section-content text-white p-[4rem] absolute top-0 flex flex-col items-center w-full'>
        <h1 className='text-[40px]'>CREATIVE TEAM</h1>
        <div className='aboutus-section-creativeTeam-section-content grid grid-cols-2 gap-[4rem] p-[1rem]'>
          {renderedCreativeTeamData}
        </div>
      </div>
    </div>
  );
}
