import React from "react";
import "./Footer.css";

import logoImage from "../../assets/logo/logo.png";

import { BsFacebook, BsInstagram, BsLinkedin } from "react-icons/bs";

import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <div className='footer-section text-white max-md:pt-[4rem]'>
      <div className='footer-section-upper border-b'>
        <img src={logoImage} alt='logoImage' />
        <div className='footer-section-content flex flex-row justify-between gap-[3rem] p-[4rem]'>
          <div className='flex flex-col gap-[1rem]'>
            <p className='text-[14px] text-justify break-all'>
              Unfold Concetto Studio strives to be a globally renowned
              architectural design enterprise dedicated to complete customer
              contentment, we leverage our strengths, including pioneering
              designs, top-tier material quality, state-of-the-art technology,
              punctual project completion, and the exhibition of utmost
              workmanship standards.
            </p>
            <div className='flex flex-row gap-[10px]'>
              <div className='bg-white p-[1rem] rounded cursor-pointer'>
                {/* <BsWhatsapp className='text-black text-[20px]' /> */}
                <a
                  href='https://www.linkedin.com/company/unfoldconcetto/
'
                  target='_blank'
                  rel='noreferrer'>
                  <BsLinkedin className='text-black text-[20px]' />
                </a>
              </div>
              <div className='bg-white p-[1rem] rounded cursor-pointer'>
                <a
                  href='https://www.facebook.com/profile.php?id=100095397230180&mibextid=2JQ9oc

'
                  target='_blank'
                  rel='noreferrer'>
                  <BsFacebook className='text-black text-[20px]' />
                </a>
              </div>
              <div className='bg-white p-[1rem] rounded cursor-pointer'>
                <a
                  href='https://instagram.com/unfold_concetto?igshid=NzZhOTFlYzFmZQ==

'
                  target='_blank'
                  rel='noreferrer'>
                  <BsInstagram className='text-black text-[20px]' />
                </a>
              </div>
            </div>
          </div>
          <div className='flex flex-col gap-[1rem]'>
            <h3 className='text-[20px]'>LINKS</h3>
            <div>
              <Link to='/'>
                <p>Home</p>
              </Link>
              <Link to='/AboutUs'>
                <p>About Us</p>
              </Link>
              <Link to='/Services'>
                <p>Services</p>
              </Link>
              <Link to='/Projects'>
                <p>Projects</p>
              </Link>
              <Link to='/Blogs'>
                <p>Blog</p>
              </Link>
              <Link to='/ContactUs'>
                <p>Contact Us</p>
              </Link>
            </div>
          </div>

          <div className='flex flex-col gap-[1rem]'>
            <h3 className='text-[20px]'>OUR SERVICES</h3>
            <div>
              <Link to={`/Services/3D Visualization`}>
                <p>3D Visualization</p>
              </Link>
              <Link to={`/Services/Architecture`}>
                <p>Architecture</p>
              </Link>
              <Link to={`/Services/Interior Design`}>
                <p>Interior Design</p>
              </Link>
              <Link to={`/Services/Site Pictures (Upcoming & On-Going)`}>
                <p>Site Pictures (Upcoming & On-Going)</p>
              </Link>
              {/* <Link to={`/Services/Landscape`}>
                <p>Landscape</p>
              </Link> */}
            </div>
          </div>

          <div className='flex flex-col gap-[1rem]'>
            <h3 className='text-[20px]'>LET'S TALK</h3>
            <p>Have a project in mind? Let's Build Something Great Together</p>
            <a href='tel:+919898509124'>+91 98985 09124</a>
            <a href='mailto:hello@unfoldconcetto.in'>hello@unfoldconcetto.in</a>
          </div>
        </div>
      </div>
      <div className='footer-section-lower p-[2rem] flex flex-row justify-center'>
        <p>©2023 All right reserved.</p>
        {/* <p>Terms of use | Privacy Environmental Policy</p> */}
      </div>
    </div>
  );
}
