import React from "react";
import "./HeroSectionOfPages.css";
import { Link } from "react-router-dom";

import ArrowImg from "../../assets/Arrow.png";

export default function HeroSectionOfPages({ image, heading, link }) {
  return (
    <div className='HeroSectionOfPagesComp relative pb-[3rem] flex justify-center'>
      <div className='HeroSectionOfPagesComp-main relative flex flex-row justify-center items-center'>
        <img
          className='HeroSectionOfPagesComp-main-Image h-screen w-screen'
          src={image}
          alt='mainAboutImg'
          style={{ objectFit: "cover" }}
        />
        <div className='absolute text-white px-[1rem] flex flex-col items-center'>
          <h1 className='HeroSectionOfPagesComp-main-heading text-[60px]'>
            {heading}
          </h1>
          <div className='flex flex-row gap-[1rem] justify-center'>
            <Link to={link}>
              <p className='text-[#B9B9B999] cursor-pointer'>Home</p>
            </Link>
            <p className='cursor-default'>{heading}</p>
          </div>
        </div>
      </div>
      <img
        className='HeroSectionOfPagesComp-arrowImg absolute bottom-0'
        src={ArrowImg}
        alt='arrowImg'
      />
    </div>
  );
}
