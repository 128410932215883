import React from "react";
import "./OurProjectComp.css";

import { IoIosArrowForward } from "react-icons/io";

import { Link } from "react-router-dom";

export default function OurProjectsComp({ ourProjectData }) {
  const [activeCategory, setActiveCategory] = React.useState("all");

  const categoryStyle = "text-[#FF965A] underline text-[25px]";

  let renderedCategoryCards = ourProjectData.all.map((data, index) => {
    return (
      <div
        key={`${data.heading}${data.subtext}${index}`}
        className='ourProject-section-card relative px-[2rem] py-[4rem]  w-full flex flex-row justify-center'>
        <img className='w-[500px] h-[550px]' src={data.image} alt='' />

        <div className='bg-black px-[2rem] py-[1rem] text-white absolute bottom-0 w-[60%] flex flex-col gap-[12px] text-center items-center'>
          <h3 className='text-[#FF965A] text-[25px]'>{data.heading}</h3>
          <p className=''>{`${data.subtext}`}</p>
          <Link to={`/Projects/${data.heading}`}>
            <IoIosArrowForward className='border rounded-full text-[40px] cursor-pointer' />
          </Link>
        </div>
      </div>
    );
  });

  if (activeCategory === "landscape") {
    renderedCategoryCards = ourProjectData.landscape.map((data, index) => {
      return (
        <div
          key={`${data.heading}${data.subtext}${index}`}
          className='ourProject-section-card relative px-[2rem] py-[4rem]  w-full flex flex-row justify-center'>
          <img className='w-[500px] h-[550px]' src={data.image} alt='' />

          <div className='bg-black px-[2rem] py-[1rem] text-white absolute bottom-0 w-[60%] flex flex-col gap-[12px] text-center items-center'>
            <h3 className='text-[#FF965A] text-[25px]'>{data.heading}</h3>
            <p className=''>{`${data.subtext}`}</p>
            <Link to={`/Projects/${data.heading}`}>
              <IoIosArrowForward className='border rounded-full text-[40px] cursor-pointer' />
            </Link>
          </div>
        </div>
      );
    });
  }
  if (activeCategory === "interiorDesign") {
    renderedCategoryCards = ourProjectData.interiorDesign.map((data, index) => {
      return (
        <div
          key={`${data.heading}${data.subtext}${index}`}
          className='ourProject-section-card relative px-[2rem] py-[4rem]  w-full flex flex-row justify-center'>
          <img className='w-[500px] h-[550px]' src={data.image} alt='' />

          <div className='bg-black px-[2rem] py-[1rem] text-white absolute bottom-0 w-[60%] flex flex-col gap-[12px] text-center items-center'>
            <h3 className='text-[#FF965A] text-[25px]'>{data.heading}</h3>
            <p className=''>{`${data.subtext}`}</p>
            <Link to={`/Projects/${data.heading}`}>
              <IoIosArrowForward className='border rounded-full text-[40px] cursor-pointer' />
            </Link>
          </div>
        </div>
      );
    });
  }
  if (activeCategory === "architecture") {
    renderedCategoryCards = ourProjectData.architecture.map((data, index) => {
      return (
        <div
          key={`${data.heading}${data.subtext}${index}`}
          className='ourProject-section-card relative px-[2rem] py-[4rem]  w-full flex flex-row justify-center'>
          <img className='w-[500px] h-[550px]' src={data.image} alt='' />

          <div className='bg-black px-[2rem] py-[1rem] text-white absolute bottom-0 w-[60%] flex flex-col gap-[12px] text-center items-center'>
            <h3 className='text-[#FF965A] text-[25px]'>{data.heading}</h3>
            <p className=''>{`${data.subtext}`}</p>
            <Link to={`/Projects/${data.heading}`}>
              <IoIosArrowForward className='border rounded-full text-[40px] cursor-pointer' />
            </Link>
          </div>
        </div>
      );
    });
  }
  if (activeCategory === "threeDVisualization") {
    renderedCategoryCards = ourProjectData.threeDVisualization.map(
      (data, index) => {
        return (
          <div
            key={`${data.heading}${data.subtext}${index}`}
            className='ourProject-section-card relative px-[2rem] py-[4rem]  w-full flex flex-row justify-center'>
            <img className='w-[500px] h-[550px]' src={data.image} alt='' />

            <div className='bg-black px-[2rem] py-[1rem] text-white absolute bottom-0 w-[60%] flex flex-col gap-[12px] text-center items-center'>
              <h3 className='text-[#FF965A] text-[25px]'>{data.heading}</h3>
              <p className=''>{`${data.subtext}`}</p>
              <Link to={`/Projects/${data.heading}`}>
                <IoIosArrowForward className='border rounded-full text-[40px] cursor-pointer' />
              </Link>
            </div>
          </div>
        );
      }
    );
  }

  //   console.log(ourProjectData.modelofInteriorandExterior);
  return (
    <div className='ourProjects-section flex flex-col py-[2rem] px-[4rem] gap-[2rem]'>
      <div className='ourProjects-section-categories text-white flex flex-row gap-[1rem] justify-between'>
        <p
          onClick={() => setActiveCategory("all")}
          className={activeCategory === "all" ? categoryStyle : "text-[25px]"}>
          All
        </p>
        <p
          onClick={() => setActiveCategory("threeDVisualization")}
          className={
            activeCategory === "threeDVisualization"
              ? categoryStyle
              : "text-[25px]"
          }>
          3D Visualization
        </p>
        <p
          onClick={() => setActiveCategory("architecture")}
          className={
            activeCategory === "architecture" ? categoryStyle : "text-[25px]"
          }>
          Architecture
        </p>
        <p
          onClick={() => setActiveCategory("interiorDesign")}
          className={
            activeCategory === "interiorDesign" ? categoryStyle : "text-[25px]"
          }>
          Interior Design
        </p>
        {/* <p
          onClick={() => setActiveCategory("landscape")}
          className={
            activeCategory === "landscape" ? categoryStyle : "text-[25px]"
          }>
          Landscape
        </p> */}
      </div>
      <div className='ourProjects-section-cards grid grid-cols-2'>
        {renderedCategoryCards}
      </div>
    </div>
  );
}
