import React, { useEffect } from "react";
import "./Homepage.css";
// import heroBGImage from "../../assets/homeImages/heroImage.png";

import SwiperCarousel from "../../components/SwiperCarousel/SwiperCarousel";

// services cards icons
import icon1 from "../../assets/homeImages/servicesIcons/1.png";
import icon4 from "../../assets/homeImages/servicesIcons/2.png";
import icon3 from "../../assets/homeImages/servicesIcons/3.png";
import icon2 from "../../assets/homeImages/servicesIcons/4.png";

// About Section Image
import AboutSectionImage from "../../assets/homeImages/homeAboutSection.png";

// Our Project
import OurProjectsComp from "../../components/ourProjectComp/OurProjectsComp";

// Latest New Comp
import LatestNewsComp from "../../components/latestNewsComp/LatestNewsComp";

// DownLine Image
import downLineImg from "../../assets/homeImages/downLine.png";

// Contact Image
import contactImage from "../../assets/homeImages/contactImage.png";

import { BsArrowRight } from "react-icons/bs";
import { FiCircle } from "react-icons/fi";
import ContactComp from "../../components/contactComp/ContactComp";

import { useLocation } from "react-router-dom";
import { useContext } from "react";
import ApiContext from "../../context/Api";

import { Link } from "react-router-dom";

// import heroCardImage from "../../assets/1.PNG";

import heroCardImage1 from "../../assets/homeImages/heroSectionCardImages/1.PNG";
import heroCardImage2 from "../../assets/homeImages/heroSectionCardImages/2.PNG";
import heroCardImage3 from "../../assets/homeImages/heroSectionCardImages/3.PNG";
import heroCardImage4 from "../../assets/homeImages/heroSectionCardImages/4.PNG";
import heroCardImage5 from "../../assets/homeImages/heroSectionCardImages/5.PNG";

export default function Homepage({
  latestNewsData,
  ourProjectData,
  heroCarouselData,
}) {
  const location = useLocation();

  const { setCurrentPath } = useContext(ApiContext);

  useEffect(() => {
    setCurrentPath(location.pathname);
  });

  const renderedHeroCarousel = heroCarouselData.map((data, index) => {
    return (
      <div
        key={index}
        className='hero-section relative flex h-screen flex-row items-center text-white'
        style={{
          backgroundImage: `url('${data.img}')`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}>
        <div className='hero-section-maindiv flex flex-col gap-[1rem] px-[4rem]'>
          <h1 className='hero-section-maindiv-heading text-[70px]'>
            {data.text}
          </h1>
          <p>{data.subtext}</p>
          <p>{data.subtext2}</p>

          <button className='bg-black w-fit p-[10px] rounded-[6px] flex flex-row gap-[8px] items-center'>
            <p>Discover</p>
            <div className='flex flex-row gap-[4px] items-center'>
              <FiCircle className='text-[10px]' />
              <BsArrowRight className='text-[30px]' />
            </div>
          </button>
        </div>
        {/* <img className='absolute bottom-0 w-full' src={HeroImageCurve} alt='' /> */}
      </div>
    );
  });

  // const ourProjectData = [
  //   all: {
  //     image
  //   }
  // ]
  return (
    <div className='homepage-section flex flex-col'>
      <div className='homepage-section-hero-section relative'>
        <SwiperCarousel data={renderedHeroCarousel} />

        <div className='homepage-section-hero-section-services-cards1 flex flex-row absolute z-[1000] bottom-0 w-full text-white'>
          <div
            className='flex flex-col hover:border-white border-[#404040] border-2 transition duration-[0.4s] ease-in-out border-solid hover:bg-[#303030] bg-[#272727]'
            style={{
              backgroundImage: `url(${heroCardImage1})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }}>
            <img
              className='bg-white rounded-full h-[50px] w-[50px] p-[8px]'
              src={icon4}
              alt='services-icon2'
            />
            <Link to='/Services/3D Visualization'>
              <p className='hover:underline'>3D Visualization</p>
            </Link>
          </div>
          <div
            className='flex flex-col hover:border-white border-[#404040] border-2 transition duration-[0.4s] ease-in-out border-solid hover:bg-[#303030] bg-[#272727]'
            style={{
              backgroundImage: `url(${heroCardImage2})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }}>
            <img
              className='bg-white rounded-full h-[50px] w-[50px] p-[8px]'
              src={icon1}
              alt='services-icon1'
            />
            <Link to='/Services/Architecture'>
              <p className='hover:underline'>Architecture</p>
            </Link>
          </div>
        </div>
      </div>
      <div className='homepage-section-hero-section-services-cards2 flex flex-row justify-end text-white'>
        <div
          className='flex flex-col hover:border-white border-[#404040] border-2 transition duration-[0.4s] ease-in-out border-solid hover:bg-[#303030] bg-[#272727]'
          style={{
            backgroundImage: `url(${heroCardImage3})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}>
          <img
            className='bg-white rounded-full h-[50px] w-[50px] p-[8px]'
            src={icon2}
            alt='services-icon2'
          />
          <Link to='/Services/Interior Design'>
            <p className='hover:underline'>Interior Design</p>
          </Link>
        </div>
        <div
          className='flex flex-col hover:border-white border-[#404040] border-2 transition duration-[0.4s] ease-in-out border-solid hover:bg-[#303030] bg-[#272727]'
          style={{
            backgroundImage: `url(${heroCardImage4})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}>
          <img
            className='bg-white rounded-full h-[50px] w-[50px] p-[8px]'
            src={icon2}
            alt='services-icon2'
          />
          <Link to='/Services/Site Pictures (Upcoming & On-Going)'>
            <p className='hover:underline'>
              Site Pictures (Upcoming & On-Going)
            </p>
          </Link>
        </div>
        <div
          className='flex flex-col hover:border-white border-[#404040] border-2 transition duration-[0.4s] ease-in-out border-solid hover:bg-[#303030] bg-[#272727]'
          style={{
            backgroundImage: `url(${heroCardImage5})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}>
          <img
            className='bg-white rounded-full h-[50px] w-[50px] p-[8px]'
            src={icon3}
            alt='services-icon1'
          />
          {/* <Link to='/Services/Landscape'> */}
          <p className='hover:underline'>Landscape (Coming Soon)</p>
          {/* </Link> */}
        </div>
      </div>

      <div className='homepage-section-aboutus flex flex-row py-[2rem] px-[4rem] text-white items-center'>
        <div className='w-[50%] flex flex-col gap-[2rem]'>
          <h3 className='text-[40px]'>Welcome to Unfold Concetto Studio</h3>
          <p className='text-[14px] text-justify'>
            An entire range of tailored design solutions aimed at showcasing the
            finest aspects of your space.
          </p>
          <p className='text-[14px] text-justify'>
            Unfold Concetto Studio stands as a professional architectural &
            interior design firm. Our comprehensive services encompass both
            residential and commercial properties across this expanse.
            Noteworthy appearances on multiple reputed platforms, alongside our
            achievements over the years, underscore our reputation. With an aim
            to cater to individual requirements, we take pride in crafting
            distinct, contemporary, and refined designs that epitomize modern
            elegance.
          </p>
        </div>
        <img
          className='w-[50%] p-[1rem]'
          src={AboutSectionImage}
          alt='AboutSectionImage'
        />
      </div>

      <div className='ourProjects-section-headingandViewAll flex flex-row justify-between items-center px-[4rem]'>
        <p className='text-white text-[40px]'>OUR PROJECTS</p>
        <Link to='/Projects'>
          <p className='text-[#FF965A] underline cursor-pointer'>View All</p>
        </Link>
      </div>
      <OurProjectsComp ourProjectData={ourProjectData} />

      <p className='homepage-section-blog-heading text-white text-[40px] py-[2rem] px-[4rem]'>
        LATEST NEWS
      </p>
      <LatestNewsComp
        latestNewsData={latestNewsData}
        compstyle={
          "flex flex-row gap-[2rem] w-full overflow-x-scroll pb-[1rem]"
        }
      />

      <div className='flex flex-row justify-center'>
        <img src={downLineImg} alt='downLineImg' />
      </div>

      <div className='homepage-section-contactImage py-[2rem] px-[4rem]'>
        <div className='homepage-section-contact relative'>
          <img src={contactImage} alt='contactImageHome' />
          <div className='homepage-section-contactSection absolute bottom-0 w-full h-[50%]'>
            <ContactComp />
          </div>
        </div>
      </div>
    </div>
  );
}
