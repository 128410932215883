import React, { useState } from "react";
import "./Header.css";

import logoImage from "../../assets/logo/logo.png";

import { Link } from "react-router-dom";

// import { GiHamburgerMenu } from "react-icons/gi";

import Hamburger from "hamburger-react";

export default function Header() {
  const [colorChange, setColorChange] = React.useState(false);

  const [dropdown, setDropdown] = useState(false);

  const changeNavbarColor = () => {
    if (window.scrollY >= 80) {
      setColorChange(true);
    } else {
      setColorChange(false);
    }
  };
  window.addEventListener("scroll", changeNavbarColor);

  const navbarStyle =
    "header-section-main flex flex-row items-center justify-between fixed w-full px-[3rem] border-b z-[10000] relative";

  return (
    <div className='navbar-section flex flex-col w-full fixed z-[100000]'>
      <div
        className={colorChange ? `${navbarStyle} bg-[#272727]` : navbarStyle}>
        <Link to='/'>
          <div className='header-section-logo'>
            <Link to='/'></Link>
            <img className='w-[170px]' src={logoImage} alt='header-logo' />
          </div>
        </Link>
        <div className='header-section-links flex flex-row gap-[2rem] text-white'>
          <div className='flex flex-row gap-[2rem] max-md:hidden'>
            <p className='text-white'>
              <Link to='/'>Home</Link>
            </p>
            <p className='text-white'>
              <Link to='/AboutUs'>About Us</Link>
            </p>
            <p className='text-white'>
              <Link to='/Services'>Services</Link>
            </p>
            <p className='text-white'>
              <Link to='/Projects'>
                <p>Projects</p>
              </Link>
            </p>
            <p className='text-white'>
              <Link to='/Blogs'>
                <p>Blogs</p>
              </Link>
            </p>
          </div>
        </div>

        <Link to='/ContactUs'>
          <button className='header-section-contact-btn bg-[#3D3D3D] p-[10px] rounded-[6px] text-white w-fit'>
            Contact Us
          </button>
        </Link>

        {/* <GiHamburgerMenu
          onClick={() => setDropdown(!dropdown)}
          className='header-section-hamIcon text-white text-[35px]'
        /> */}
        <div className='header-section-hamIcon'>
          <Hamburger color='white' toggled={dropdown} toggle={setDropdown} />
        </div>
      </div>
      {/* Mobile Dropdown */}
      {dropdown && (
        <div className='header-section-mobile-dropdown flex flex-col gap-[3rem] bg-white h-screen w-full p-[4rem]'>
          <p onClick={() => setDropdown(false)}>
            <Link to='/'>Home</Link>
          </p>
          <p onClick={() => setDropdown(false)}>
            <Link to='/AboutUs'>About Us</Link>
          </p>
          <p onClick={() => setDropdown(false)}>
            <Link to='/Services'>Services</Link>
          </p>
          <p onClick={() => setDropdown(false)}>
            <Link to='/Projects'>Projects</Link>
          </p>
          <p onClick={() => setDropdown(false)}>
            <Link to='/Blogs'>Blogs</Link>
          </p>
          <Link to='/ContactUs'>
            <button
              onClick={() => setDropdown(false)}
              className='header-section-contact-btnMobile bg-[#3D3D3D] p-[10px] rounded-[6px] text-white w-fit'>
              Contact Us
            </button>
          </Link>
        </div>
      )}
    </div>
  );
}
