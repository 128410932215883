import React from "react";
import "./ServicesComp.css";

import { Link } from "react-router-dom";

export default function ServicesComp({ servicesData }) {
  console.log(servicesData);

  const renderedServicesData = servicesData.map((data, index) => {
    return (
      <div key={index} className='services-section-card relative rounded-[6px]'>
        <div className='services-section-card-sub relative'>
          <img
            className='h-[500px] w-full rounded-[6px]'
            src={data.image}
            alt={`${data.serviceName}${index}`}
          />
          <h1 className='text-[30px] absolute bottom-0 text-white p-[1rem]'>
            {data.serviceName}
          </h1>
        </div>

        <div className='services-section-card-aboveDiv bg-transparent hover:bg-[#272727] border-[2px] border-solid border-white rounded-[6px] w-full h-full absolute top-0 transition duration-[0.3s] hidden ease-in-out flex flex-col gap-[1rem] text-white p-[1rem]'>
          <h2 className='text-[22px] border-b py-[1rem] px-[1rem]'>
            {data.serviceName}
          </h2>
          <div className='flex flex-col gap-[1rem] p-[1rem]'>
            <p>{data.text}</p>
            <Link to={`/Services/${data.serviceName}`}>
              <button className='underline text-[#FF965A] w-fit'>
                Read More...
              </button>
            </Link>
          </div>
        </div>
      </div>
    );
  });
  return (
    <div className='services-section-comp grid grid-cols-2 gap-[2rem] p-[4rem]'>
      {renderedServicesData}
    </div>
  );
}
