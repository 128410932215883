import React, { useEffect } from "react";
import "./Projects.css";

import projectsHeroImage from "../../assets/projects/projectsHeroImage.jpg";
import HeroSectionOfPages from "../../components/heroSectionOfPages/HeroSectionOfPages";

import OurProjectsComp from "../../components/ourProjectComp/OurProjectsComp";

import ContactImage from "../../assets/contactBGImg.jpg";

import ContactComp from "../../components/contactComp/ContactComp";

import { useLocation } from "react-router-dom";
import { useContext } from "react";
import ApiContext from "../../context/Api";

export default function Projects({ ourProjectData }) {
  const location = useLocation();

  const { setCurrentPath } = useContext(ApiContext);

  useEffect(() => {
    setCurrentPath(location.pathname);
  });

  return (
    <div className='projects-section'>
      <HeroSectionOfPages
        image={projectsHeroImage}
        heading={"Our Projects"}
        link={"/"}
      />

      <OurProjectsComp ourProjectData={ourProjectData} />

      <div className='aboutus-section-contact relative'>
        <img className='' src={ContactImage} alt='ContactImage' />
        <div className='absolute top-0 w-full h-full'>
          <ContactComp />
        </div>
      </div>
    </div>
  );
}
