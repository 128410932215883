import React, { useEffect } from "react";
import "./SingleProjectPage.css";

import HeroSectionOfPages from "../../../components/heroSectionOfPages/HeroSectionOfPages";
import singleProjectPageHeroImage from "../../../assets/projects/singleProjectPage/singleProjectPageHeroImage.jpg";

import { useLocation } from "react-router-dom";
import { useContext } from "react";
import ApiContext from "../../../context/Api";

// import mainImage from "../../../assets/projects/singleProjectPage/projectPage/project1/main.jpg";
// import image1 from "../../../assets/projects/singleProjectPage/projectPage/project1/1.jpg";
// import image2 from "../../../assets/projects/singleProjectPage/projectPage/project1/2.jpg";

import ContactImage from "../../../assets/contactBGImg.jpg";

import ContactComp from "../../../components/contactComp/ContactComp";

import { useParams } from "react-router-dom";

export default function SingleProjectPage({ ourProjectData }) {
  const { SingleProjectPageId } = useParams();

  const projectPageData = ourProjectData.all.find((data) => {
    return SingleProjectPageId === data.heading;
  });

  // console.log(projectPageData);

  const location = useLocation();

  const { setCurrentPath } = useContext(ApiContext);

  useEffect(() => {
    setCurrentPath(location.pathname);
  });

  return (
    <div className='singleProjectPage-section flex flex-col'>
      <HeroSectionOfPages
        image={singleProjectPageHeroImage}
        heading={projectPageData.heading}
        link={"/"}
      />

      <div className='singleProjectPage-section-main flex flex-row gap-[1rem] px-[4rem] py-[2rem]'>
        <div className='singleProjectPage-section-main-left w-[70%]'>
          <img
            className='w-full h-[350px] rounded-[5px] max-md:h-[200px]'
            src={projectPageData.pageImage1}
            alt='mainImage'
          />
          <div className='flex flex-row gap-[2rem] py-[2rem]'>
            <img
              className='w-[50%] h-[350px] rounded-[5px]'
              src={projectPageData.pageImage2}
              alt='image1'
            />
            <img
              className='w-[50%] h-[350px] rounded-[5px]'
              src={projectPageData.pageImage3}
              alt='image2'
            />
          </div>
        </div>
        <div className='singleProjectPage-section-main-right w-[30%] text-white flex flex-col gap-[1rem]'>
          <p className='text-justify'>{projectPageData.subtext}</p>

          <p className='text-justify'>{projectPageData.subtext2}</p>

          <div className='flex flex-col gap-[10px]'>
            <div className='flex flex-row gap-[10px]'>
              <strong className='text-[#FF965A]'>Client</strong>
              <p>Bellway Homes</p>
            </div>
            <div className='flex flex-row gap-[10px]'>
              <strong className='text-[#FF965A]'>Number of Homes</strong>
              <p>3701</p>
            </div>
            <div className='flex flex-row gap-[10px]'>
              <strong className='text-[#FF965A]'>Tenure Mix</strong>
              <p>30% affordable, 70% private</p>
            </div>
            <div className='flex flex-row gap-[10px]'>
              <strong className='text-[#FF965A]'>Site Size</strong>
              <p>12ha</p>
            </div>
            <div className='flex flex-row gap-[10px]'>
              <strong className='text-[#FF965A]'>Planning Approved</strong>
              <p>July 2022</p>
            </div>
          </div>
        </div>
      </div>
      <div className='aboutus-section-contact relative'>
        <img className='' src={ContactImage} alt='ContactImage' />
        <div className='absolute top-0 w-full h-full'>
          <ContactComp />
        </div>
      </div>
    </div>
  );
}
