import React from "react";
import "./AliceCarousel.css";

import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";

// const handleDragStart = (e) => e.preventDefault();

// const items = [
//   <img src='path-to-img' onDragStart={handleDragStart} role='presentation' />,
//   <img src='path-to-img' onDragStart={handleDragStart} role='presentation' />,
//   <img src='path-to-img' onDragStart={handleDragStart} role='presentation' />,
// ];

export default function Carousel(renderedOurClientsData) {
  const responsive = {
    0: {
      items: 1,
    },
    568: {
      items: 2,
    },
    1024: {
      items: 3,
      itemsFit: "contain",
    },
  };
  const items = renderedOurClientsData.renderedOurClientsData;
  //   console.log();
  //   const items = [...renderedOurClientsData];
  return (
    <AliceCarousel
      activeIndex
      mouseTracking
      responsive={responsive}
      items={items}
    />
  );
}
