import { createContext, useState } from "react";

const ApiContext = createContext();

function ApiProvider({ children }) {
  const [currentPath, setCurrentPath] = useState(window.location.pathname);

  return (
    <ApiContext.Provider
      value={{
        currentPath,
        setCurrentPath,
      }}>
      {children}
    </ApiContext.Provider>
  );
}

export { ApiProvider };
export default ApiContext;
