import React from "react";
import "./ContactComp.css";

import contactLocationImg from "../../assets/contactLocationImg.png";

export default function ContactComp() {
  return (
    <div className='contact-section-comp text-white p-[2rem] h-full'>
      <p className='contact-section-comp-heading text-white text-[40px]'>
        CONTACT US
      </p>
      <div className='contact-section-comp-content flex flex-row justify-between items-center'>
        <div className='contact-section-comp-content1 flex flex-col gap-[8px]'>
          <p className='text-[#FF965A]'>PHONE</p>
          <a href='tel:+919898509124'>+91 98985 09124</a>
          <p>Monday - Friday: 8am - 6pmSaturday - Sunday: 9am - 3pm</p>
        </div>
        <div className='contact-section-comp-content2 flex flex-col gap-[8px]'>
          <p className='text-[#FF965A]'>EMAIL & ADDRESS</p>
          <a href='mailto:hello@unfoldconcetto.in'>hello@unfoldconcetto.in</a>
          <p className='w-[300px]'>
            Shree Shakti Society behind Avishkar Complex Old Padra Road, 12/A ,
            Vadodara, Gujarat 390020, IN
          </p>
        </div>
        <div className='contact-section-comp-content3 flex flex-col gap-[10px] items-center'>
          <div className='bg-white rounded-full p-[2rem]'>
            <img
              className='h-[70px] w-[70px]'
              src={contactLocationImg}
              alt='contactLocationImg'
            />
          </div>
          <p>Our Location</p>
        </div>
      </div>
    </div>
  );
}
