import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";

import { ApiProvider } from "./context/Api";

const el = document.getElementById("root");
const root = ReactDOM.createRoot(el);

root.render(
  <ApiProvider>
    <App />
  </ApiProvider>
);
