import React, { useEffect } from "react";
import "./SingleServicePage.css";

import HeroSectionOfPages from "../../../components/heroSectionOfPages/HeroSectionOfPages";

import singleServiceHeroImage from "../../../assets/services/singleService/singleServiceHeroImage.jpg";

import ContactImage from "../../../assets/contactBGImg.jpg";

import ContactComp from "../../../components/contactComp/ContactComp";

import { IoIosArrowForward } from "react-icons/io";

import { useLocation } from "react-router-dom";
import { useContext } from "react";
import ApiContext from "../../../context/Api";

import { useParams, Link } from "react-router-dom";

export default function SingleServicePage({ servicesData }) {
  const location = useLocation();

  const { SingleServicePageId } = useParams();

  const servicePageData = servicesData.find((data) => {
    return SingleServicePageId === data.serviceName;
  });

  // console.log(servicePageData);

  const { setCurrentPath } = useContext(ApiContext);

  useEffect(() => {
    setCurrentPath(location.pathname);
  });
  return (
    <div className='singleServicePage-section'>
      <HeroSectionOfPages
        image={singleServiceHeroImage}
        heading={servicePageData.serviceName}
        link={"/"}
      />

      <div className='singleServicePage-section-content flex flex-col py-[2rem] px-[4rem] text-white'>
        <p className='text-justify'>{servicePageData.pageText}</p>

        <div className='singleServicePage-section-main flex flex-row gap-[2rem] py-[2rem]'>
          <div className='singleServicePage-section-main-left w-[70%]'>
            <img
              className='w-full h-[350px] rounded-[5px] max-md:h-[200px]'
              src={servicePageData.pageImage1}
              alt='mainImage'
            />
            <div className='flex flex-row gap-[2rem] py-[2rem]'>
              <img
                className='w-[50%] h-[350px] rounded-[5px]'
                src={servicePageData.pageImage2}
                alt='image1'
              />
              <img
                className='w-[50%] h-[350px] rounded-[5px]'
                src={servicePageData.pageImage3}
                alt='image2'
              />
            </div>
          </div>
          <div className='singleServicePage-section-main-right w-[30%] text-white flex flex-col gap-[1rem]'>
            <div className='bg-[#303030]'>
              <h3 className='p-[2rem] text-center border-b'>All Services</h3>
              <div className='flex flex-col gap-[1rem] p-[1rem]'>
                <Link to={`/Services/Architecture`}>
                  <div
                    className={`flex flex-row justify-between p-[1rem] items-center cursor-pointer ${
                      servicePageData.serviceName === "Architecture"
                        ? "bg-[#FFB58B] opacity-70"
                        : null
                    }`}>
                    <p>Architecture</p>
                    <IoIosArrowForward />
                  </div>
                </Link>
                <Link to={`/Services/Site Pictures (Upcoming & On-Going)`}>
                  <div
                    className={`flex flex-row justify-between p-[1rem] items-center cursor-pointer ${
                      servicePageData.serviceName ===
                      "Site Pictures (Upcoming & On-Going)"
                        ? "bg-[#FFB58B] opacity-70"
                        : null
                    }`}>
                    <p>Site Pictures (Upcoming & On-Going)</p>
                    <IoIosArrowForward />
                  </div>
                </Link>
                <Link to={`/Services/3D Visualization`}>
                  <div
                    className={`flex flex-row justify-between p-[1rem] items-center cursor-pointer ${
                      servicePageData.serviceName === "3D Visualization"
                        ? "bg-[#FFB58B] opacity-70"
                        : null
                    }`}>
                    <p>3D Visualization</p>
                    <IoIosArrowForward />
                  </div>
                </Link>
                <Link to={`/Services/Interior Design`}>
                  <div
                    className={`flex flex-row justify-between p-[1rem] items-center cursor-pointer ${
                      servicePageData.serviceName === "Interior Design"
                        ? "bg-[#FFB58B] opacity-70"
                        : null
                    }`}>
                    <p>Interior Design</p>
                    <IoIosArrowForward />
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='aboutus-section-contact relative'>
        <img className='' src={ContactImage} alt='ContactImage' />
        <div className='absolute top-0 w-full h-full'>
          <ContactComp />
        </div>
      </div>
    </div>
  );
}
