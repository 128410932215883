import React from "react";
import "./OurClients.css";

import aboutOurClientsBgImg from "../../assets/aboutus/ourClientsBgImg.jpg";
import Carousel from "../aliceCarousel/AliceCarousel";

export default function OurClients() {
  const ourClientsData = [
    {
      text: '"Sovaye tristique usto duision vitae diamen neque nivamus aesta atene artines arinianu ateli finibus viverra nec lacus. Nedana dinoz setlie suscipe none misster cuvvan tristique. Developer inila duman aten elit finibus viveracus themone the dru."',
      name: "Olivia Brown",
      designation: "Manager at HMM Arch.",
    },
    {
      text: '"Sovaye tristique usto duision vitae diamen neque nivamus aesta atene artines arinianu ateli finibus viverra nec lacus. Nedana dinoz setlie suscipe none misster cuvvan tristique. Developer inila duman aten elit finibus viveracus themone the dru."',
      name: "Olivia Brown",
      designation: "Manager at HMM Arch.",
    },
    {
      text: '"Sovaye tristique usto duision vitae diamen neque nivamus aesta atene artines arinianu ateli finibus viverra nec lacus. Nedana dinoz setlie suscipe none misster cuvvan tristique. Developer inila duman aten elit finibus viveracus themone the dru."',
      name: "Olivia Brown",
      designation: "Manager at HMM Arch.",
    },
    {
      text: '"Sovaye tristique usto duision vitae diamen neque nivamus aesta atene artines arinianu ateli finibus viverra nec lacus. Nedana dinoz setlie suscipe none misster cuvvan tristique. Developer inila duman aten elit finibus viveracus themone the dru."',
      name: "Olivia Brown",
      designation: "Manager at HMM Arch.",
    },
    {
      text: '"Sovaye tristique usto duision vitae diamen neque nivamus aesta atene artines arinianu ateli finibus viverra nec lacus. Nedana dinoz setlie suscipe none misster cuvvan tristique. Developer inila duman aten elit finibus viveracus themone the dru."',
      name: "Olivia Brown",
      designation: "Manager at HMM Arch.",
    },
    {
      text: '"Sovaye tristique usto duision vitae diamen neque nivamus aesta atene artines arinianu ateli finibus viverra nec lacus. Nedana dinoz setlie suscipe none misster cuvvan tristique. Developer inila duman aten elit finibus viveracus themone the dru."',
      name: "Olivia Brown",
      designation: "Manager at HMM Arch.",
    },
  ];

  const renderedOurClientsData = ourClientsData.map((data, index) => {
    return (
      <div
        key={index}
        className='aboutUs-ourClients-comp-card text-white h-[400px] w-full border-[1rem] border-transparent border-solid'>
        <div className='aboutUs-ourClients-comp-card-content flex flex-col bg-[#272727] opacity-50 transition duration-[0.4s] ease-in-out p-[2rem] gap-[2rem] hover:opacity-100'>
          <p>{data.text}</p>
          <div className='flex flex-col gap-[8px]'>
            <p>{data.name}</p>
            <p>{data.designation}</p>
          </div>
        </div>
      </div>
    );
  });
  return (
    <div className='aboutUs-ourClients-comp relative flex flex-col items-center'>
      <img src={aboutOurClientsBgImg} alt='aboutOurClientsBgImg' />
      <div className='aboutUs-ourClients-comp-head flex flex-row absolute top-0 p-[2rem]'>
        <h1 className='text-[40px] text-white'>Our Clients</h1>
      </div>
      <div className='aboutUs-ourClients-comp-cards absolute w-full top-[6rem] p-[2rem]'>
        <Carousel renderedOurClientsData={renderedOurClientsData} />
      </div>
    </div>
  );
}
