import React, { useEffect } from "react";
import "./AboutUs.css";

import mainAboutImg from "../../assets/aboutus/mainImg.png";
import aboutImg1 from "../../assets/aboutus/aboutImg1.png";
import ContactImage from "../../assets/contactBGImg.jpg";
import HeroSectionOfPages from "../../components/heroSectionOfPages/HeroSectionOfPages";
import OurClients from "../../components/ourClients/OurClients";
import CreativeTeam from "../../components/creativeTeam/CreativeTeam";
import ContactComp from "../../components/contactComp/ContactComp";

import { useLocation } from "react-router-dom";
import { useContext } from "react";
import ApiContext from "../../context/Api";

export default function AboutUs() {
  const location = useLocation();

  const { setCurrentPath } = useContext(ApiContext);

  useEffect(() => {
    setCurrentPath(location.pathname);
  });

  return (
    <div className='aboutus-section'>
      <HeroSectionOfPages
        image={mainAboutImg}
        heading={"About Us"}
        link={"/"}
      />
      <div className='aboutus-section-25YEARSOFEXPERIENCE flex flex-row py-[2rem] px-[4rem] text-white items-center'>
        <div className='w-[50%] flex flex-col gap-[1rem]'>
          <h3 className='text-[40px]'>ABOUT UNFOLD CONCETTO STUDIO</h3>
          <p className='text-[14px] text-justify'>
            Unfold Concetto Studio designs culminate in opulent surroundings
            characterized by elegant color combinations, polished materials, and
            an enduring intricacy that embodies a timeless and classic
            sensibility.
          </p>
          <p className='text-[14px] text-justify'>
            <p className='text-[14px] text-justify'>
              Unfold Concetto Studio designs culminate in opulent surroundings
              characterized by elegant color combinations, polished materials,
              and an enduring intricacy that embodies a timeless and classic
              sensibility.
            </p>
          </p>
          <p className='text-[14px] text-justify'>
            At Unfold Concetto Studio, we provide comprehensive turnkey
            solutions for interior, architectural, and 3D Interior Model Design
            projects. Our well-rounded expertise enables us to flawlessly
            execute these projects according to our clients' precise
            specifications.
          </p>
        </div>
        <img className='w-[50%] p-[1rem]' src={aboutImg1} alt='aboutImg1' />
      </div>

      <div className='aboutus-sectionourVisionANDOurMission flex flex-col gap-[1rem] text-white py-[2rem] px-[4rem]'>
        <h3 className='text-[40px]'>Our Vision!</h3>
        <p className='text-[14px] text-justify'>
          Our aspiration is to become a globally acclaimed interior &
          architectural enterprise dedicated to achieving utmost customer
          contentment. We aim to achieve this by capitalizing on our strengths,
          including innovative designs, exceptional material quality,
          cutting-edge technology, on-time project completion, and the
          showcasing of the highest levels of workmanship.
        </p>

        <h3 className='text-[40px]'>Our Mission!</h3>
        <p className='text-[14px] text-justify'>
          We are committed to assisting every individual in recognizing the
          worth of their finances, emotions, and aspirations by crafting
          comfortable living spaces that promote a sense of tranquility and
          contentment.
        </p>
      </div>

      <OurClients />

      <CreativeTeam />

      <div className='aboutus-section-contact relative'>
        <img className='' src={ContactImage} alt='ContactImage' />
        <div className='absolute top-0 w-full h-full'>
          <ContactComp />
        </div>
      </div>
    </div>
  );
}
