import React, { useEffect } from "react";
import "./Blog.css";

import blogHeroImage from "../../assets/blog/blogHeroImage.jpg";
import HeroSectionOfPages from "../../components/heroSectionOfPages/HeroSectionOfPages";

import LatestNewsComp from "../../components/latestNewsComp/LatestNewsComp";

import { useLocation } from "react-router-dom";
import { useContext } from "react";
import ApiContext from "../../context/Api";

export default function Blog({ latestNewsData }) {
  const location = useLocation();

  const { setCurrentPath } = useContext(ApiContext);

  useEffect(() => {
    setCurrentPath(location.pathname);
  });

  return (
    <div className='blog-section'>
      <HeroSectionOfPages
        image={blogHeroImage}
        heading={"Blog News"}
        link={"/"}
      />

      <LatestNewsComp
        latestNewsData={latestNewsData}
        compstyle={"blog-section-cards grid grid-cols-3 gap-[1rem]"}
      />
    </div>
  );
}
