import React from "react";
import "./ContactForm.css";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";

export default function ContactForm() {
  const formSchema = Yup.object().shape({
    contactUserName: Yup.string()
      .required("Name is required")
      .min(6, "Name must be at least 6 char long")
      .max(20, "Name must be at least 20 char long"),
    contactEmail: Yup.string()
      .email("Not a valid Email")
      .required("Email is required"),
    contactNumber: Yup.string()
      .required("Contact is required")
      .min(10, "Contact must be at least 10 char long")
      .max(13, "Contact must be at least 10 char long"),
    contactMessage: Yup.string().required("Message is required"),
  });

  const formOptions = { resolver: yupResolver(formSchema) };
  const {
    register,
    handleSubmit,
    // reset,
    formState,
  } = useForm(formOptions);
  const { errors } = formState;

  const isValidEmail = (email) =>
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      email
    );

  //Email Validation
  const handleEmailValidation = (email) => {
    // console.log('ValidateEmail was called with', email);

    const isValid = isValidEmail(email);

    // const validityChanged =
    //   (errors.email && isValid) || (!errors.email && !isValid);
    // if (validityChanged) {
    //   console.log('Fire tracker with', isValid ? 'Valid' : 'Invalid');
    // }

    return isValid;
  };

  function onSubmit(data) {
    console.log(data);
  }

  return (
    <div className='contact-form-section flex flex-col gap-[1rem] text-white'>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className='contactUs-section-main-form flex flex-col gap-[1rem]'>
        <div className='contactUs-section-main-form-subInput flex flex-col gap-[10px] border-b'>
          <label>Full Name</label>
          <input
            {...register("contactUserName")}
            name='contactUserName'
            required
            type='text'
            // className='bg-transparent outline-none w-full p-[10px]'
            className={`form-control ${
              errors.contactUserName
                ? "is-invalid bg-transparent outline-none w-full p-[10px] border-2 border-red-400 border-solid"
                : "bg-transparent outline-none w-full p-[10px]"
            }`}
            placeholder='write your full name'
          />
        </div>
        <div className='contactUs-section-main-form-subInputWithRow flex flex-row gap-[1rem]'>
          <div className='flex flex-col gap-[10px] border-b w-full'>
            <label>Email</label>
            <input
              {...register("contactEmail", {
                required: true,
                validate: handleEmailValidation,
              })}
              name='contactEmail'
              required
              type='email'
              // className='bg-transparent outline-none w-full p-[10px]'
              className={`form-control ${
                errors.contactEmail
                  ? "is-invalid bg-transparent outline-none w-full p-[10px] border-2 border-red-400 border-solid"
                  : "bg-transparent outline-none w-full p-[10px]"
              }`}
              placeholder='write your email'
            />
          </div>
          <div className='flex flex-col gap-[10px] border-b w-full'>
            <label>Phone Number</label>
            <input
              {...register("contactNumber")}
              name='contactNumber'
              required
              type='number'
              // className='bg-transparent outline-none p-[10px]'
              className={`form-control ${
                errors.contactNumber
                  ? "is-invalid bg-transparent outline-none w-full p-[10px] border-2 border-red-400 border-solid"
                  : "bg-transparent outline-none w-full p-[10px]"
              }`}
              placeholder='write your phone number'
            />
          </div>
        </div>
        <div className='flex flex-col gap-[8px] border-b'>
          <label>Message</label>
          <textarea
            {...register("contactMessage")}
            name='contactMessage'
            required
            type='text'
            className={`form-control ${
              errors.contactMessage
                ? "is-invalid bg-transparent outline-none py-[1rem] px-[10px] border-2 border-red-400 border-solid"
                : "bg-transparent outline-none py-[1rem] px-[10px]"
            }`}
            placeholder='write your message'
          />
        </div>
      </form>
      <button
        onClick={handleSubmit(onSubmit)}
        className='bg-[#FF965A] py-[1rem] px-[2rem] w-fit rounded-[5px]'>
        Send Message
      </button>
    </div>
  );
}
